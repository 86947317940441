import { CdkScrollable }           from '@angular/cdk/overlay';
import { ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { CommonModule }            from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
}                                  from '@angular/core';
import { MatButtonModule }         from '@angular/material/button';
import { MatCardModule }           from '@angular/material/card';
import {
  MatDialogModule,
  MatDialogRef,
}                                  from '@angular/material/dialog';
import { MatFormFieldModule }      from '@angular/material/form-field';
import { MatIconModule }           from '@angular/material/icon';
import { MatProgressSpinner }      from '@angular/material/progress-spinner';
import { TranslateModule }         from '@ngx-translate/core';
import { snakeCase }               from 'lodash';
import { Observable }              from 'rxjs';

export enum DialogButton {
  OK,
  Cancel,
}

export type DialogButtonConfig<T extends string | number = DialogButton> = {
  id: T;
  label: string;
  icon?: string;
  style: 'primary' | 'secondary' | 'negative';
  autoClose?: boolean;
  disabled$?: Observable<boolean>;
  isLoading$?: Observable<boolean>;
};

export type DialogConfig<T extends string | number = DialogButton> = {
  title?: string;
  titleTranslateArgs?: unknown;
  closeable?: boolean;
  compact?: boolean;
  errorKey$?: Observable<string | null>;
  errorContext?: string;
  buttons: DialogButtonConfig<T>[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinner,
    TranslateModule,
    CdkScrollable,
  ],
  selector: 'rb-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent<T extends string | number = DialogButton> {
  @Input()
  config?: DialogConfig<T> | null;

  @Output()
  buttonClick = new EventEmitter<T>();

  @ViewChild(CdkScrollable)
  scrollContainer?: CdkScrollable;

  constructor(private readonly dialogRef: MatDialogRef<DialogComponent<T>>) {
  }

  fullErrorKey(errorKey: string) {
    return (this.config?.errorContext ? this.config.errorContext + '.' : '') + snakeCase(errorKey).toUpperCase();
  }

  close<T>(result?: T) {
    this.dialogRef.close(result);
  }

  onDialogButtonClick(dialogButton: DialogButtonConfig<T>) {
    const { id, autoClose = true } = dialogButton;
    this.buttonClick.emit(id);
    if (autoClose) {
      this.close();
    }
  }

  scrollTo(options: ExtendedScrollToOptions) {
    this.scrollContainer?.scrollTo(options);
  }
}
