import { Moment }       from 'moment';
import {
  FileExtension,
  ShareResponse,
}                       from '../enums';
import { DocumentType } from './';

export type SimpleDocument = {
  id: string;
  name: string;
  extension: FileExtension;
  shareResponse?: ShareResponse;
}

export type RbDocument = {
  id: string,
  name: string,
  extension: FileExtension,
  createdAt: Date,
  hidden: boolean,
  ownDocument: boolean,
  shared: boolean,
  type: DocumentType,
  owner?: DocumentOwner,
}

export type DocumentFilters = {
  startDate: Moment | null,
  endDate: Moment | null,
  types?: DocumentType[],
  userIds?: string[],
  showHidden: boolean,
}

export enum DocumentFilterParams {
  startDate = 'startDate',
  endDate = 'endDate',
  showHidden = 'showHidden',
  types = 'types',
  userIds = 'userIds',
  searchQuery = 'searchQuery'
}

export type DocumentOwner = {
  id: string,
  firstName?: string,
  lastName?: string,
}

export const SystemDocumentOwner = 'SYSTEM';
