import { NgIf }         from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                       from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'rb-form-field',
  standalone: true,
  imports: [NgIf, MatFormField],
  templateUrl: './form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {
  @Input()
  label?: string;
  @Input()
  truncateLabel: boolean = true;
  @Input()
  required: boolean = false;
}
