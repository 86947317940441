import { Moment }        from 'moment/moment';
import { PaymentStatus } from '../enums';
import {
  BankAccount,
  Beneficiary,
  CreditorRemittanceInformationType,
  SimpleClient,
}                        from './';

export type PaymentFilters = {
  status: PaymentStatus | null;
};

export type Payment = {
  id: string;
  status: PaymentStatus;
  amount: number;
  counterpartName: string;
  counterpartReference: string;
  remittanceInformationType: CreditorRemittanceInformationType;
  remittanceInformation: string;
  executionDate?: Date;
  client: SimpleClient;
  bankAccount: BankAccount;
}

export type PaymentData = {
  id?: string;
  clientId?: string;
  accountId?: string;
  amount?: number;
  counterpartName?: string;
  counterpartReference?: string;
  remittanceInformationType?: CreditorRemittanceInformationType;
  remittanceInformation?: string;
  executionDate?: Moment | null;
}

export type PaymentFormData = {
  clientId: string;
  accountId: string;
  amount: number | null;
  beneficiary: Beneficiary | null;
  remittanceInformationType: CreditorRemittanceInformationType;
  remittanceInformation: string;
  executionDate: Moment | null;
  anotherPayment: boolean;
}
