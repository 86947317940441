import { NotificationType } from '../enums';
import {
  BankAccount,
  SimpleClient,
  SimpleDocument,
}                           from './';

export type Notification = {
  id: string;
  bankAccount?: BankAccount;
  client: SimpleClient;
  dateCreated: Date;
  closed: boolean;
  closedBySystem: boolean;
  title: string;
  description: string;
  details?: string;
  document?: SimpleDocument;
  subNotifications: SubNotification[]
}

export type SubNotification = {
  dateCreated: Date;
  description: string;
}

export type NotificationFilters = {
  onlyUnprocessed: boolean;
  type: NotificationType | null;
}
