import { Moment }                            from 'moment/moment';
import { CreditorRemittanceInformationType } from './';

export type DividendInfo = {
  totalAmount?: number;
  payments: CreditorPayment[];
}

export type CreditorPayment = {
  position: number;
  name: string;
  iban?: string;
  remittanceInformation?: string;
  remittanceInformationType?: CreditorRemittanceInformationType;
  remainingDebt?: number;
}

export type DividendFormData = {
  accountId: string;
  executionDate: Moment | null;
  totalAmount: number | null;
  payments: CreditorPaymentFormData[];
}

export type CreditorPaymentFormData = {
  position: number;
  counterpartName: string;
  counterpartReference: string | null;
  remittanceInformation: string | null;
  remittanceInformationType: CreditorRemittanceInformationType;
  amount: number | null;
}
