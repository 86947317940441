import { HttpErrorResponse }     from '@angular/common/http';
import { Type }                  from '@angular/core';
import { MatDateFormats }        from '@angular/material/core';
import {
  ExtraOptions,
  Routes,
}                                from '@angular/router';
import { SwRegistrationOptions } from '@angular/service-worker';
import { routerReducer }         from '@ngrx/router-store';
import { ActionReducerMap }      from '@ngrx/store';
import { StoreDevtoolsOptions }  from '@ngrx/store-devtools';
import {
  TranslateLoader,
  TranslateModuleConfig,
}                                from '@ngx-translate/core';
import { ErrorHandlerOptions }   from '@sentry/angular-ivy';
import { GlobalConfig }          from 'ngx-toastr';
import { of }                    from 'rxjs';
import { environment }           from '~environment';
import {
  API_ERROR_IDENTITY,
  LOGIN_REDIRECT_URL,
}                                from './app.constants';
import { AppRoute }              from './app.route.enum';
import { appRoutes }             from './app.routes';
import { HttpErrorConfig }       from './core/interceptors';
import { AppEffects }            from './state/app.effects';
import { appReducer }            from './state/app.reducer';
import en                        from './translations/en.json';
import fr                        from './translations/fr.json';
import nl                        from './translations/nl.json';

const TRANSLATIONS: Record<string, object> = { nl, fr, en };

export const serviceWorkerConfig: [string, SwRegistrationOptions] = ['ngsw.js', { enabled: environment.production }];
export const routerConfig: [Routes, ExtraOptions] = [appRoutes, { paramsInheritanceStrategy: 'always' }];
export const ngxTranslateConfig: [TranslateModuleConfig] = [
  {
    loader: {
      provide: TranslateLoader,
      useValue: {
        getTranslation: (lang: string) => of(TRANSLATIONS[lang]),
      },
    },
    defaultLanguage: 'en',
    useDefaultLang: true,
  },
];
export const ngxToastrConfig: [Partial<GlobalConfig>] = [{ preventDuplicates: true }];
export const ngrxStoreConfig: [ActionReducerMap<unknown>] = [{ app: appReducer, router: routerReducer }];
export const ngrxEffectsConfig: Type<unknown>[] = [AppEffects];
export const ngrxStoreDevToolsConfig: [StoreDevtoolsOptions] = [{ maxAge: 25, logOnly: environment.production }];
export const httpErrorConfig: HttpErrorConfig = {
  identity: API_ERROR_IDENTITY,
  redirects: {
    401: { route: AppRoute.Login, withOrigin: LOGIN_REDIRECT_URL },
  },
  registry: {
    400: 'BAD_REQUEST',
    403: 'FORBIDDEN',
    404: 'NOT_FOUND',
  },
};
export const sentryErrorHandlerConfig: [ErrorHandlerOptions] = [
  {
    logErrors: false,
    extractor: (error: unknown, defaultExtractor: (error: unknown) => unknown) => {
      if (error instanceof HttpErrorResponse) {
        const message = `${error.message}\n${JSON.stringify(error.error || {}, undefined, 2)}`;
        return Object.assign(new Error(message), { name: 'HttpError' });
      }
      return defaultExtractor(error);
    },
  },
];
export const matDateFormats: MatDateFormats = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
