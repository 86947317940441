import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry                from '@sentry/angular-ivy';
import packageJson                from 'package.json';
import { AppModule }              from '~app/app.module';
import { enableDebugMode }        from '~debug';
import { environment }            from '~environment';
import '~utils/extensions';
import '~utils/url';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://0045d055fc635bb1890a669bde8e689f@o198172.ingest.sentry.io/4506194854412288',
    release: packageJson.version,
    environment: environment.name,
    integrations: [new Sentry.BrowserTracing({ routingInstrumentation: Sentry.instrumentAngularRouting })],
    tracesSampleRate: 0.25,
    tracePropagationTargets: [environment.api],
    ignoreErrors: [/unauthorized/i],
  });
}

if (!environment.production) {
  enableDebugMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
