import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
}                                            from '@angular/forms';
import { Moment }                            from 'moment';
import { map }                               from 'rxjs';
import { CreditorRemittanceInformationType } from '~domain/types';
import { ValidatorService }                  from './validator.service';

export class CustomValidators {

  public static ibanValidator(validatorService: ValidatorService): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return validatorService.isValidIban(control.value)
        .pipe(map(valid => {
          if (valid) {
            return null;
          }
          return { iban: true };
        }));
    };
  }

  public static dateRangeValidator(startDateControlName: string, endDateControlName: string) {
    const key = 'endBeforeStart';
    const errors = { endBeforeStart: true };

    return (group: AbstractControl) => {
      const startDateControl = group.get(startDateControlName);
      const endDateControl = group.get(endDateControlName);

      if (!startDateControl || !endDateControl) {
        return null;
      }

      if (
        (startDateControl.errors && !startDateControl.errors[key]) ||
        (endDateControl.errors && !endDateControl.errors[key])
      ) {
        // Another error with higher priority is already present
        if (startDateControl.errors && startDateControl.errors[key]) {
          startDateControl.setErrors(null);
        }
        if (endDateControl.errors && endDateControl.errors[key]) {
          endDateControl.setErrors(null);
        }
        return null;
      }

      const startDate = startDateControl.value as Moment | null;
      const endDate = endDateControl.value as Moment | null;

      if (startDate && endDate && endDate < startDate) {
        startDateControl.setErrors(errors);
        endDateControl.setErrors(errors);
        return errors;
      } else {
        startDateControl.setErrors(null);
        endDateControl.setErrors(null);
        return null;
      }
    };
  }

  public static remittanceInformationValidator(controlName: string, typeControlName: string) {
    const key = 'remittanceInformation';
    const errors: ValidationErrors = { remittanceInformation: true };

    return (group: AbstractControl) => {
      const control = group.get(controlName);
      const typeControl = group.get(typeControlName);

      if (!control || !typeControl) {
        return null;
      }

      if (control.errors && !control.errors[key]) {
        // Another error with higher priority is already present
        return null;
      }

      const value = (control.value as string | null)?.trim() || null;
      const type = typeControl.value as CreditorRemittanceInformationType | null;

      if (value === null || value === '' || type === null) {
        return null;
      }

      if (type === CreditorRemittanceInformationType.structured) {
        const v = value.replace(/\//g, '');
        const number = Number(v.slice(0, 10));
        const checksum = Number(v.slice(10));
        if (
          v.length !== 12 ||
          Number.isNaN(number) ||
          Number.isNaN(checksum) ||
          !(number % 97 === 0 && checksum === 97) &&
          !(number % 97 === checksum && checksum !== 0)
        ) {
          control.setErrors(errors);
          return errors;
        }
        control.setErrors(null);
        return null;
      }

      if (
        /[^a-zA-Z0-9/\-?:().,'+\s]/g.test(value) ||
        value.startsWith('/') ||
        value.endsWith('/') ||
        value.includes('//')
      ) {
        control.setErrors(errors);
        return errors;
      }
      control.setErrors(null);
      return null;
    };
  }
}
