import { NgIf }            from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                          from '@angular/core';
import { MatIcon }         from '@angular/material/icon';
import { MatTooltip }      from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rb-view-field',
  standalone: true,
  imports: [NgIf, MatIcon, MatTooltip, TranslateModule],
  templateUrl: './view-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFieldComponent {
  @Input()
  label?: string;

  @Input()
  tooltip?: string;
}
