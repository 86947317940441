import { TranslateService } from '@ngx-translate/core';

declare module '@ngx-translate/core/dist/lib/translate.service' {
  interface TranslateService {
    optional(key: string | Array<string>, interpolateParams?: object): string | undefined;
  }
}

TranslateService.prototype.optional = function optional(
  key: string | Array<string>,
  interpolateParams?: object,
): string | undefined {
  const result = this.instant(key, interpolateParams);
  return result === key ? undefined : result;
};
