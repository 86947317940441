import { Injectable } from '@angular/core';
import { Store }      from '@ngrx/store';
import {
  Beneficiary,
  PaymentData,
}                     from '~domain/types';
import {
  addBeneficiary,
  addPayment,
  deleteBeneficiary,
  fetchBeneficiaries,
  updateBeneficiary,
  updatePayment,
}                     from '../state/app.actions';
import {
  selectBeneficiaries,
  selectBeneficiariesLoading,
  selectBeneficiaryEditing,
  selectPaymentAccounts,
  selectPaymentClients,
  selectPaymentSaving,
}                     from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  paymentAccounts$ = this.store.select(selectPaymentAccounts);
  paymentClients$ = this.store.select(selectPaymentClients);
  beneficiaries$ = this.store.select(selectBeneficiaries);
  beneficiariesLoading$ = this.store.select(selectBeneficiariesLoading);
  paymentSaving$ = this.store.select(selectPaymentSaving);
  beneficiaryEditing$ = this.store.select(selectBeneficiaryEditing);

  constructor(private readonly store: Store) {
  }

  savePayment(paymentData: PaymentData, anotherPayment: boolean, reloadPayments?: boolean) {
    if (!paymentData.id) {
      this.store.dispatch(addPayment({ paymentData, anotherPayment, reloadPayments }));
    } else {
      this.store.dispatch(updatePayment({ paymentData }));
    }
  }

  fetchBeneficiaries(clientId: string) {
    this.store.dispatch(fetchBeneficiaries({ clientId }));
  }

  updateBeneficiary(beneficiaryId: string, clientId: string, beneficiary: Beneficiary) {
    this.store.dispatch(updateBeneficiary({ beneficiaryId, clientId, beneficiary }));
  }

  addBeneficiary(clientId: string, beneficiary: Beneficiary) {
    this.store.dispatch(addBeneficiary({ clientId, beneficiary }));
  }

  deleteBeneficiary(beneficiaryId: string, clientId: string) {
    this.store.dispatch(deleteBeneficiary({ beneficiaryId, clientId }));
  }
}
