<form [formGroup]="form" class="flex flex-col gap-y-[2rem]">
  <div class="grid grid-cols-2 gap-x-[1rem]">
    <div class="flex flex-col gap-y-[1rem]">
      <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.NAME' | translate" [required]="true">
        <mat-form-field>
          <input [placeholder]="'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.NAME' | translate"
                 formControlName="counterpartName"
                 matInput
                 rbViewChild
                 required
                 type="text" />
        </mat-form-field>
      </rb-form-field>
      <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.IBAN' | translate" [required]="true">
        <mat-form-field>
          <input [placeholder]="'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.IBAN' | translate"
                 formControlName="counterpartReference"
                 matInput
                 rbIban
                 required
                 type="text" />
        </mat-form-field>
      </rb-form-field>
    </div>
    <mat-radio-group class="vertical mt-[2.5rem]" formControlName="type">
      <mat-radio-button [value]="'single-use'">
        <div class="flex gap-x-[0.5rem]">
          <mat-icon class="icon-20 icon-brand mr-0" svgIcon="counterpart-single-use"></mat-icon>
          {{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.SINGLE_USE' | translate }}
        </div>
      </mat-radio-button>
      <mat-radio-button [value]="'client'">
        <div class="flex gap-x-[0.5rem] h-[3.5rem] items-center">
          <mat-icon class="icon-20 icon-brand mr-0" svgIcon="counterpart-client"></mat-icon>
          {{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.CLIENT' | translate }}
        </div>
      </mat-radio-button>
      <mat-radio-button [value]="'global'">
        <div class="flex gap-x-[0.5rem]">
          <mat-icon class="icon-20 icon-brand mr-0" svgIcon="counterpart-global"></mat-icon>
          {{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.GLOBAL' | translate }}
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="flex justify-end gap-x-[2rem]">
    <button (click)="cancelEditBeneficiary()" class="button-secondary" mat-button>
      <mat-icon svgIcon="cross"></mat-icon>
      {{ 'APP.BUTTON.CANCEL.LABEL' | translate }}
    </button>
    <button (click)="confirmEditBeneficiary()"
            [disabled]="(form.statusChanges | async) !== 'VALID'"
            class="button-primary"
            mat-button>
      <mat-icon *ngIf="!beneficiaryEditing" svgIcon="check"></mat-icon>
      <mat-spinner *ngIf="beneficiaryEditing" class="spinner-brand" diameter="15"></mat-spinner>
      <span>{{ 'APP.BUTTON.CONFIRM.LABEL' | translate }}</span>
    </button>
  </div>
</form>
