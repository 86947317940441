<rb-filter (filterCleared)="clearFilter()"
           (filterSet)="setFilter()"
           [disabled]="disabled"
           [labelShort]="'APP.FILTERS.DATE.TITLE_SHORT' | translate"
           [label]="title || 'APP.FILTERS.DATE.TITLE_SHORT' | translate"
           [value]="filter.dateFrom !== null || filter.dateTo !== null
                    ? (getLabel() | translate : {
                      dateFrom: filter.dateFrom?.toDate() | date,
                      dateTo: filter.dateTo?.toDate() | date
                    })
                    : undefined">
  <form [formGroup]="form" class="flex gap-x-[1rem] py-[0.5rem]">
    <rb-form-field [label]="'APP.FILTERS.DATE.FROM' | translate">
      <mat-form-field class="w-[150px]">
        <input [matDatepicker]="pickerFrom" formControlName="dateFrom" matInput placeholder="dd-mm-yyyy" />
        <mat-datepicker-toggle [for]="pickerFrom" matIconSuffix>
          <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.FILTERS.DATE.TO' | translate">
      <mat-form-field class="w-[150px]">
        <input [matDatepicker]="pickerTo" formControlName="dateTo" matInput placeholder="dd-mm-yyyy" />
        <mat-datepicker-toggle [for]="pickerTo" matIconSuffix>
          <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </rb-form-field>
  </form>
</rb-filter>
