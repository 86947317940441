<rb-filter (filterCleared)="clearFilter()"
           (filterSet)="selectFilter()"
           [disabled]="disabled"
           [label]="label"
           [value]="valuesSet && valuesSet.length > 0 ? valueLabel : undefined">
  <form class="flex flex-col gap-y-0 mb-[-0.5rem]">
    <mat-form-field *ngIf="filter">
      <input #searchField
             (input)="filterValues(searchField.value)"
             [value]="value"
             matInput
             placeholder="{{ 'SEARCH_FIELD.PLACEHOLDER' | translate }}"
             type="text" />
      <mat-icon matIconSuffix svgIcon="search"></mat-icon>
    </mat-form-field>
    <div class="h-[16rem] block overflow-auto">
      <mat-selection-list (selectionChange)="selectionChange($event)" multiple="true">
        <div *ngFor="let val of filteredValues">
          <mat-list-option [selected]="valuesToSet.includes(optionValue(val))"
                           [value]="optionValue(val)"
                           togglePosition="before">
            <ng-container *ngTemplateOutlet="templateRef; context: { item: val }"></ng-container>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </div>
  </form>
</rb-filter>
