import { Moment }                from 'moment';
import { UpdateTransactionType } from '../enums';
import { Creditor }              from './';

export type Transaction = {
  id: string;
  executionDate: Date;
  valueDate: Date;
  amount: number;
  intraday: boolean;
  category?: Category;
  counterpartReference?: string;
  counterpartName?: string;
  description?: string;
  remittanceInformation?: string;
  remittanceInformationType?: RemittanceInformationType;
  creditor?: Creditor;
  tags: Tag[];
};

export enum RemittanceInformationType {
  structured = 'structured',
  unstructured = 'unstructured',
}

export type Category = {
  mainCategoryName: string;
  subCategoryName: string;
  mainCategoryLabel: string;
  subCategoryLabel: string;
  icon: string;
  debtRepayment: boolean;
};

export type CustomCategory = {
  mainCategory: string;
  subCategory: string;
};

export type MainCategory = {
  mainCategoryName: string;
  mainCategoryLabel: string;
  icon: string;
};

export type UpdateTransactionCategoryRequest = {
  mainCategoryName: string;
  subCategoryName: string;
  creditorId?: string;
} & UpdateTransactionRequest;

export type UpdateTransactionTagsRequest = {
  tagIds: string[];
} & UpdateTransactionRequest;

export type UpdateTransactionRequest = {
  type: UpdateTransactionType;
  counterpartReference: string;
  counterpartName: string;
  description: string;
  remittanceInformation: string;
  dateFrom: Moment | null;
  dateTo: Moment | null;
  amountMin: number | null;
  amountMax: number | null;
  category: Category | null;
};

export type Tag = {
  id: string;
  name: string;
}
