import {
  BasicDocumentType,
  RbDocumentType,
} from '../enums';


export type DocumentType = BasicDocumentType | RbDocumentType;

export function isRbDocumentType(type: DocumentType) {
  const rbDocumentTypes: DocumentType[] = Object.values(RbDocumentType);
  return rbDocumentTypes.includes(type);
}
