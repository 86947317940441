import { InjectionToken }  from '@angular/core';
import { HttpErrorConfig } from './core/interceptors';

export const API_ERROR_IDENTITY = 'code';
export const AUTHORIZATION_HEADER = 'Authorization';
export const ACCESS_TOKEN = 'access-token';
export const LOGIN_REDIRECT_URL = 'login-redirect-url';

export const ENVIRONMENT = new InjectionToken<Environment>('environment');
export const LOCATION = new InjectionToken<Location>('location');
export const LOCAL_STORAGE = new InjectionToken<Storage>('local-storage');
export const HTTP_ERROR_CONFIG = new InjectionToken<HttpErrorConfig>('http-error-config');
