import {
  ChangeDetectionStrategy,
  Component,
  Inject,
}                                   from '@angular/core';
import { NonNullableFormBuilder }   from '@angular/forms';
import { MAT_DIALOG_DATA }          from '@angular/material/dialog';
import {
  FileExtension,
  fileIcon,
  needsBrandColor,
  ShareResponse,
}                                   from '~domain/enums';
import { Notification }             from '~domain/types';
import {
  DialogButton,
  DialogConfig,
}                                   from '~shared/components';
import { RespondToDocumentService } from './respond-to-document.service';

export type RespondToDocumentRequest = {
  notification: Notification,
  response: ShareResponse,
}


@Component({
  selector: 'rb-respond-to-document-dialog',
  templateUrl: './respond-to-document-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RespondToDocumentDialogComponent {

  documentUpdating$ = this.respondToDocumentService.documentUpdating$;
  documentDownloadingId$ = this.respondToDocumentService.documentDownloadingId$;
  responseOptions = Object.values(ShareResponse);

  dialogConfig: DialogConfig = {
    title: 'APP.CLIENTS.CLIENT_NOTIFICATIONS.RESPOND_TO_DOCUMENT.TITLE',
    closeable: true,
    buttons: [
      {
        id: DialogButton.Cancel,
        label: 'APP.BUTTON.CANCEL.LABEL',
        icon: 'cross',
        style: 'secondary',
      },
      {
        id: DialogButton.OK,
        label: 'APP.BUTTON.CONFIRM.LABEL',
        icon: 'check',
        style: 'primary',
        autoClose: false,
        isLoading$: this.documentUpdating$,
      },
    ],
  };

  form = this.fb.group({
    response: this.fb.control(this.request.response),
    message: this.fb.control(''),
  });
  protected readonly ShareResponse = ShareResponse;
  protected readonly fileIcon = fileIcon;
  protected readonly needsBrandColor = needsBrandColor;

  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly respondToDocumentService: RespondToDocumentService,
    @Inject(MAT_DIALOG_DATA) protected request: RespondToDocumentRequest) {
  }

  onDialogButtonClick(dialogButton: DialogButton) {
    if (dialogButton === DialogButton.OK && this.request.notification.document) {
      this.form.markAllAsTouched();
      this.respondToDocumentService.respondToDocument(this.request.notification.document.id, this.form.controls.response.value, this.request.notification.client.id, this.form.controls.message.value);
    }
  }

  downloadDocument(id: string, name: string, extension: FileExtension) {
    this.respondToDocumentService.downloadDocument(id, this.request.notification.client.id, name, extension);
  }
}
