import { DOCUMENT }                    from '@angular/common';
import {
  Inject,
  Injectable,
}                                      from '@angular/core';
import { MatDialog }                   from '@angular/material/dialog';
import { Router }                      from '@angular/router';
import {
  Actions,
  createEffect,
  ofType,
}                                      from '@ngrx/effects';
import { TranslateService }            from '@ngx-translate/core';
import {
  catchError,
  concatMap,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
}                                      from 'rxjs';
import {
  BeneficiaryType,
  mimeType,
}                                      from '~domain/enums';
import {
  Beneficiary,
  CreditorRemittanceInformationType,
  PaymentFormData,
}                                      from '~domain/types';
import { AddClientApiService }         from '../add-client/add-client-api.service';
import { LOCAL_STORAGE }               from '../app.constants';
import {
  CounterApiService,
  DocumentApiService,
  OfficeApiService,
  Toast,
  ToastService,
  UserApiService,
}                                      from '../core';
import { PaymentApiService }           from '../payment/payment-api.service';
import { PaymentDialogComponent }      from '../payment/payment-dialog.component';
import { RespondToDocumentApiService } from '../respond-to-document/respond-to-document-api.service';
import * as AppActions                 from './app.actions';

@Injectable()
export class AppEffects {
  fetchColorMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchColorMode),
      map(() => AppActions.fetchColorModeResult({
        colorMode: this.localStorage.getItem('colorMode') as 'dark' | 'light' | null,
      })),
    ),
  );

  setColorMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setColorMode),
      map(({ colorMode }) => {
        if (colorMode) this.localStorage.setItem('colorMode', colorMode);
        else this.localStorage.removeItem('colorMode');
        return AppActions.setColorModeResult({ colorMode });
      }),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchUser),
      switchMap(() => this.userApiService.fetchUser()),
      tap(({ language }) => this.translateService.use(language)),
      map(user => AppActions.fetchUserResult({ user })),
    ),
  );

  fetchClientBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchClientBadgeCount, AppActions.respondToDocumentResult),
      switchMap(() => this.counterApiService.getNumberOfClientsWithNotifications()),
      map(count => AppActions.fetchClientBadgeCountResult({ count })),
    ),
  );

  fetchNotificationBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchNotificationBadgeCount, AppActions.respondToDocumentResult),
      switchMap(() => this.counterApiService.getNumberOfOpenNotifications()),
      map(count => AppActions.fetchNotificationBadgeCountResult({ count })),
    ),
  );

  fetchPaymentBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchPaymentBadgeCount, AppActions.addPaymentResult),
      switchMap(() => this.counterApiService.getNumberOfQueuedPayments()),
      map(count => AppActions.fetchPaymentBadgeCountResult({ count })),
    ),
  );

  fetchUnlinkedAccountsBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchUnlinkedAccountsBadgeCount),
      switchMap(() => this.counterApiService.getNumberOfUnlinkedAccounts()),
      map(count => AppActions.fetchUnlinkedAccountsBadgeCountResult({ count })),
    ),
  );

  fetchAccountsWithWarningBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAccountsWithWarningBadgeCount),
      switchMap(() => this.counterApiService.getNumberOfAccountsWithWarning()),
      map(count => AppActions.fetchAccountsWithWarningBadgeCountResult({ count })),
    ),
  );

  fetchOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchUserResult),
      switchMap(({ user }) => this.officeApiService.fetchOffice(user.officeId)),
      map(office => AppActions.fetchOfficeResult({ office })),
    ),
  );

  closeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.closeDialog),
      tap(({ message }) => {
        this.matDialog.closeAll();
        if (message) {
          this.toastService.show(Toast.Success, message);
        }
      }),
    ), { dispatch: false });

  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClient),
      concatMap(({ client }) =>
        this.addClientApiService.createClient(client).pipe(
          map(id => AppActions.createClientResult({ id, accountId: client.initialAccountId })),
          catchError(() => of(AppActions.createClientError())),
        ),
      ),
    ),
  );

  closeCreateClientDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClientResult),
      map(() => AppActions.closeDialog({ message: 'ADD_CLIENT' })),
    ),
  );

  navigateAfterCreatedClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClientResult),
      tap(({ id, accountId }) =>
        this.router.navigate(
          [accountId ? '/bank-accounts/' + accountId : '/clients/' + id],
          { onSameUrlNavigation: 'reload' },
        ),
      ),
    ), { dispatch: false });

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.downloadDocument),
      concatMap(({ id, clientId, name, extension }) => this.documentApiService.downloadDocument(id, clientId)
        .pipe(
          map((file) => {
            const objectURL = window.URL.createObjectURL(
              new Blob([file], { type: mimeType(extension) }),
            );
            const downloadAnchorNode = this.document.createElement('a');
            downloadAnchorNode.setAttribute('href', objectURL);
            downloadAnchorNode.setAttribute('download', `${name}.${extension}`);
            this.document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
            this.toastService.show(Toast.Success, 'DOCUMENT_DOWNLOADED');
            return AppActions.downloadDocumentResult();
          }),
          catchError(() => of(AppActions.downloadDocumentError())),
        ),
      ),
    ),
  );

  respondToDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.respondToDocument),
      concatMap(({ id, clientId, response, message }) =>
        this.respondToDocumentApiService.respondToDocument(id, response, clientId, message)
          .pipe(
            map(() => AppActions.respondToDocumentResult()),
            catchError(() => of(AppActions.respondToDocumentError())),
          ),
      ),
    ),
  );

  closeRespondToDocumentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.respondToDocumentResult),
      map(() => AppActions.closeDialog({ message: 'DOCUMENT_RESPONSE_ADDED' })),
    ),
  );

  fetchInfoPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchInfoPaymentDialog),
      switchMap(({ paymentData, reloadPayments }) =>
        forkJoin(([
          this.paymentApiService.fetchAccounts(),
          this.paymentApiService.fetchClients(),
          paymentData.clientId ? this.paymentApiService.fetchBeneficiaries(paymentData.clientId) : of([]),
        ])).pipe(
          map(([paymentAccounts, paymentClients, beneficiaries]) => AppActions.fetchInfoPaymentDialogResult({
            paymentData,
            reloadPayments,
            paymentAccounts,
            paymentClients,
            beneficiaries,
          })),
          catchError(() => of(AppActions.fetchInfoPaymentDialogError())),
        ),
      ),
    ),
  );

  openPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchInfoPaymentDialogResult),
      tap(({
             paymentData,
             reloadPayments,
             beneficiaries,
           }) => {
        const existingBeneficiary = beneficiaries.find(b => b.counterpartName === paymentData.counterpartName && b.counterpartReference === paymentData.counterpartReference);
        const singleUseBeneficiary: Beneficiary | null = (paymentData.counterpartName || paymentData.counterpartReference)
          ? {
            counterpartName: paymentData.counterpartName,
            counterpartReference: paymentData.counterpartReference,
            singleUse: true,
            id: null,
            global: false,
            type: BeneficiaryType.BENEFICIARY,
          } : null;
        const paymentFormData: PaymentFormData = {
          clientId: paymentData.clientId || '',
          accountId: paymentData.accountId || '',
          amount: paymentData.amount || null,
          executionDate: paymentData.executionDate || null,
          remittanceInformation: paymentData.remittanceInformation || '',
          remittanceInformationType: paymentData.remittanceInformationType || CreditorRemittanceInformationType.unstructured,
          beneficiary: existingBeneficiary ?? singleUseBeneficiary,
          anotherPayment: false,
        };
        this.matDialog.open(PaymentDialogComponent, {
          disableClose: true,
          data: { paymentFormData, reloadPayments, id: paymentData.id },
        });
      }),
    ), { dispatch: false });

  addPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPayment),
      concatMap(({ paymentData, anotherPayment, reloadPayments }) =>
        this.paymentApiService.addPayment(paymentData)
          .pipe(
            map(() => AppActions.addPaymentResult({ anotherPayment, reloadPayments })),
            catchError(() => of(AppActions.addPaymentError())),
          ),
      ),
    ),
  );

  updatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updatePayment),
      concatMap(({ paymentData }) =>
        this.paymentApiService.updatePayment(paymentData)
          .pipe(
            map(() => AppActions.updatePaymentResult({ reloadPayments: true })),
            catchError(() => of(AppActions.updatePaymentError())),
          ),
      ),
    ),
  );


  closeUpdatePaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updatePaymentResult),
      map(() => AppActions.closeDialog({ message: 'PAYMENT_SAVED' })),
    ),
  );

  closeAddPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult),
      filter(({ anotherPayment }) => !anotherPayment),
      map(() => AppActions.closeDialog({ message: 'PAYMENT_SAVED' })),
    ),
  );

  addAnotherPaymentConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult),
      filter(({ anotherPayment }) => anotherPayment),
      tap(() =>  this.toastService.show(Toast.Success, 'PAYMENT_SAVED')),
    ), { dispatch: false }
  );

  fetchBeneficiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchBeneficiaries),
      switchMap(({ clientId }) => {
          if (clientId !== '') {
            return this.paymentApiService.fetchBeneficiaries(clientId)
              .pipe(
                map((beneficiaries) => AppActions.fetchBeneficiariesResult({ beneficiaries })),
                catchError(() => of(AppActions.fetchBeneficiariesError())),
              );
          } else {
            return of(AppActions.fetchBeneficiariesResult({ beneficiaries: [] }));
          }
        },
      ),
    ),
  );

  navigateAfterAddedPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult, AppActions.updatePaymentResult),
      filter(({ reloadPayments }) => reloadPayments === true),
      tap(() =>
        this.router.navigate(
          ['/payments'],
          {
            onSameUrlNavigation: 'reload',
            queryParamsHandling: 'merge',
          },
        ),
      ),
    ), { dispatch: false });

  updateBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateBeneficiary),
      concatMap(({ clientId, beneficiaryId, beneficiary }) =>
        this.paymentApiService.updateBeneficiary(beneficiaryId, clientId, beneficiary)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  addBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addBeneficiary),
      concatMap(({ clientId, beneficiary }) =>
        this.paymentApiService.addBeneficiary(clientId, beneficiary)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  deleteBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteBeneficiary),
      concatMap(({ clientId, beneficiaryId }) =>
        this.paymentApiService.deleteBeneficiary(beneficiaryId, clientId)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly actions$: Actions,
    private readonly addClientApiService: AddClientApiService,
    private readonly counterApiService: CounterApiService,
    private readonly documentApiService: DocumentApiService,
    private readonly matDialog: MatDialog,
    private readonly officeApiService: OfficeApiService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly userApiService: UserApiService,
    private readonly respondToDocumentApiService: RespondToDocumentApiService,
    private readonly paymentApiService: PaymentApiService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }
}
