import {
  ChangeDetectionStrategy,
  Component,
}                       from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  standalone: true,
  selector: 'rb-outlet',
  templateUrl: './outlet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class OutletComponent {
}
