import {
  ClientType,
  Language,
} from '../enums';

export type Office = {
  name: string,
  clientTypes: ClientType[];
  dummy: boolean;
  language: Language;
};
