import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Output,
}               from '@angular/core';

@Component({
  selector: 'rb-file-drop',
  standalone: true,
  imports: [NgIf],
  templateUrl: './file-drop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropComponent {
  @Output()
  filesDropped = new EventEmitter<FileList>();

  filesOver: boolean = false;

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.filesOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.filesOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.filesOver = false;
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}

@Directive({
  selector: '[rbDropZone]',
  standalone: true,
})
export class DropZoneDirective {
}
