import {
  Beneficiary,
  Office,
  PaymentAccount,
  SimpleClient,
  User,
} from '~domain/types';

export type AppState = {
  colorMode: 'dark' | 'light' | null;
  user: User | null;
  clientBadgeCount: number;
  notificationBadgeCount: number;
  paymentBadgeCount: number;
  unlinkedAccountsBadgeCount: number;
  accountsWithWarningBadgeCount: number;
  office: Office | null;
  createClientIsLoading: boolean;
  documentUpdating: boolean;
  documentDownloadingId: string | null;
  paymentAccounts: PaymentAccount[];
  paymentClients: SimpleClient[];
  beneficiaries: Beneficiary[];
  beneficiariesLoading: boolean;
  paymentInfoLoading: boolean
  paymentSaving: boolean;
  beneficiaryEditing: boolean;
};

export const initialState: AppState = {
  colorMode: null,
  user: null,
  clientBadgeCount: 0,
  notificationBadgeCount: 0,
  paymentBadgeCount: 0,
  unlinkedAccountsBadgeCount: 0,
  accountsWithWarningBadgeCount: 0,
  office: null,
  createClientIsLoading: false,
  documentUpdating: false,
  documentDownloadingId: null,
  paymentAccounts: [],
  paymentClients: [],
  beneficiaries: [],
  beneficiariesLoading: false,
  paymentSaving: false,
  paymentInfoLoading: false,
  beneficiaryEditing: false,
};
