<div class="flex gap-x-[0.5rem] items-center">
  <div class="font-bold truncate">
    <span *ngIf="client.lastName">{{ client.lastName }},</span>
    {{ client.firstName }}
  </div>
  <mat-icon *ngFor="let type of client.types"
            [svgIcon]="'ENUM.CLIENT_TYPE.' + type + '.ICON' | translate"
            class="icon-15 icon-brand"></mat-icon>
</div>
<div class="text-sm text-secondary truncate">{{ client.municipality || '-' }}</div>

