import { CommonModule }               from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
}                                     from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
}                                     from '@angular/forms';
import { MatDatepickerModule }        from '@angular/material/datepicker';
import { MatIconModule }              from '@angular/material/icon';
import { MatInputModule }             from '@angular/material/input';
import { MatSelectModule }            from '@angular/material/select';
import {
  createMask,
  InputMaskModule,
}                                     from '@ngneat/input-mask';
import { TranslateModule }            from '@ngx-translate/core';
import { Moment }                     from 'moment';
import { Language }                   from '~domain/enums';
import { ClientPersonalInfoFormData } from '~domain/types';
import { RbForm }                     from '../directives';
import { ErrorPipe }                  from '../pipes';
import { FormFieldComponent }         from './form-field.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatIconModule,
    InputMaskModule,
    MatDatepickerModule,
    ErrorPipe,
    MatSelectModule,
    FormFieldComponent,
  ],
  selector: 'rb-edit-personal-info',
  templateUrl: './edit-personal-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditPersonalInfoComponent),
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => EditPersonalInfoComponent),
      multi: true,
    },
  ],
})
export class EditPersonalInfoComponent extends RbForm {
  @Input()
  defaultLanguage: Language | null = Language.NL;
  today = new Date();
  ssnInputMask = createMask({ mask: '99.99.99-999.99', autoUnmask: true });
  override form = this.fb.group({
    firstName: this.fb.control('', { validators: [Validators.required] }),
    lastName: this.fb.control(''),
    ssn: this.fb.control(''),
    birthDate: this.fb.control<Moment | null>(null),
    phone: this.fb.control(''),
    street: this.fb.control(''),
    number: this.fb.control(''),
    postalCode: this.fb.control(''),
    municipality: this.fb.control(''),
    email: this.fb.control('', { validators: [Validators.email], updateOn: 'blur' }),
    language: this.fb.control<Language | null>(null),
  });
  languages: Language[] = Object.values(Language);

  override writeValue(data: ClientPersonalInfoFormData): void {
    this.form.setValue(data);
    if (data.language === null) {
      this.form.controls.language.setValue(this.defaultLanguage);
    }
  }
}
