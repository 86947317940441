import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
}                                 from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators,
}                                 from '@angular/forms';
import moment, { Moment }         from 'moment';
import { BeneficiaryType }        from '~domain/enums';
import {
  Beneficiary,
  CreditorRemittanceInformationType,
  PaymentAccount,
  PaymentFormData,
  SimpleClient,
}                                 from '~domain/types';
import { RbForm }                 from '~shared/directives';
import { CustomValidators }       from '../core/validators';
import { EditBeneficiaryRequest } from './edit-beneficiary.component';

@Component({
  selector: 'rb-edit-payment',
  templateUrl: './edit-payment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditPaymentComponent),
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => EditPaymentComponent),
      multi: true,
    },
  ],
})
export class EditPaymentComponent extends RbForm implements OnChanges {
  @Input({ required: true })
  paymentClients: SimpleClient[] | null = [];
  @Input({ required: true })
  paymentAccounts: PaymentAccount[] | null = [];
  @Input({ required: true })
  beneficiaries: Beneficiary[] | null = [];
  @Input()
  addPaymentEnabled = true;
  @Input()
  beneficiariesLoading: boolean | null = false;
  @Input()
  addPaymentLoading: boolean | null = false;
  @Input()
  beneficiaryEditing: boolean | null = false;
  @Output()
  fetchBeneficiaries = new EventEmitter<string>;
  @Output()
  saveBeneficiary = new EventEmitter<EditBeneficiaryRequest>;


  override form = this.fb.group({
    clientId: this.fb.control(''),
    accountId: this.fb.control(''),
    beneficiary: this.fb.control<Beneficiary | null>({
      value: null,
      disabled: true,
    }, { validators: [this.beneficiaryValidator] }),
    amount: this.fb.control<number | null>(null),
    executionDate: this.fb.control<Moment | null>(null, { updateOn: 'blur' }),
    remittanceInformation: this.fb.control<string>('', { validators: [Validators.required] }),
    remittanceInformationType: this.fb.control(CreditorRemittanceInformationType.unstructured),
    anotherPayment: this.fb.control(false),
  }, {
    validators: [
      CustomValidators.remittanceInformationValidator('remittanceInformation', 'remittanceInformationType'),
    ],
  });
  editBeneficiaryEnabled = false;
  editBeneficiaryConfirmed = false;
  editBeneficiaryValidationErrors: ValidationErrors | null = null;
  protected readonly BeneficiaryType = BeneficiaryType;

  get selectedClient() {
    return this.paymentClients?.find(client => client.id === this.form.controls.clientId.value);
  }

  get selectedAccount() {
    return this.paymentAccounts?.find(account => account.id === this.form.controls.accountId.value);
  }

  get filteredAccounts() {
    if (this.form.controls.clientId.value === '') {
      return this.paymentAccounts;
    }
    return this.paymentAccounts?.filter(account => account.clientId === this.form.controls.clientId.value).sort((a, b) => a.position - b.position);
  }

  get structured(): boolean {
    return this.form.controls.remittanceInformationType.value === CreditorRemittanceInformationType.structured;
  }

  get today() {
    return moment.utc(new Date()).startOf('day');
  }

  private get beneficiaryValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.editBeneficiaryEnabled) {
        if (this.editBeneficiaryValidationErrors !== null) {
          return this.editBeneficiaryValidationErrors;
        }
        return { editing: true };
      }
      if (control.value === null || !control.value.counterpartName || !control.value.counterpartReference) {
        return { required: true };
      }
      return null;
    };
  }

  private get beneficiary() {
    return this.beneficiaries?.find(beneficiary => beneficiary.counterpartName === this.form.controls.beneficiary.value?.counterpartName && beneficiary.counterpartReference === this.form.controls.beneficiary.value?.counterpartReference);
  }

  ngOnChanges(): void {
    if (this.form.controls.clientId.value === '' || this.beneficiariesLoading) {
      if (this.form.controls.beneficiary.enabled) {
        this.form.controls.beneficiary.disable();
      }
    } else if (this.form.controls.beneficiary.disabled) {
      this.form.controls.beneficiary.enable();
    }

    if (!this.beneficiaryEditing && this.editBeneficiaryConfirmed) {
      this.cancelEditBeneficiary();
    }
    if (!this.editBeneficiaryEnabled && this.beneficiary) {
      this.form.controls.beneficiary.setValue(this.beneficiary);
    }
  }

  override writeValue(data: PaymentFormData) {
    this.form.setValue(data);
    if (data.clientId !== '' && data.accountId === '') {
      this.selectFirstAccount();
    }
    if (data.accountId !== '' && data.clientId === '' && this.selectedAccount) {
      this.form.controls.clientId.setValue(this.selectedAccount.clientId);
      this.fetchBeneficiaries.emit(this.form.controls.clientId.value);
    }
  }

  toggleRemittanceInformationType() {
    this.form.controls.remittanceInformationType.setValue(
      this.form.controls.remittanceInformationType.value === CreditorRemittanceInformationType.unstructured ?
        CreditorRemittanceInformationType.structured :
        CreditorRemittanceInformationType.unstructured,
    );
  }

  onClientChanged() {
    this.reloadBeneficiaries();
    this.selectFirstAccount();
  }

  getBeneficiaryIcon(beneficiary: Beneficiary) {
    if (beneficiary.singleUse) {
      return 'counterpart-single-use';
    }
    if (beneficiary.id === null || !beneficiary.global) {
      return 'counterpart-client';
    }
    return 'counterpart-global';
  }

  onAccountChanged() {
    if (this.selectedAccount && this.selectedAccount.clientId !== this.form.controls.clientId.value) {
      this.form.controls.clientId.setValue(this.selectedAccount.clientId);
      this.reloadBeneficiaries();
    }
  }

  editBeneficiary() {
    this.editBeneficiaryEnabled = true;
    this.form.controls.clientId.disable();
    this.form.controls.beneficiary.markAsTouched();
    setTimeout(() => {
      this.form.controls.beneficiary.updateValueAndValidity();
    });
  }

  addBeneficiary() {
    this.form.controls.beneficiary.setValue(null);
    this.editBeneficiary();
  }

  cancelEditBeneficiary() {
    this.editBeneficiaryEnabled = false;
    this.editBeneficiaryConfirmed = false;
    this.editBeneficiaryValidationErrors = null;
    this.form.controls.clientId.enable();
    this.form.controls.beneficiary.markAsTouched();
    setTimeout(() => {
      this.form.controls.beneficiary.updateValueAndValidity();
    });
  }

  confirmEditBeneficiary(beneficiary: Beneficiary) {
    this.editBeneficiaryConfirmed = true;
    this.form.controls.beneficiary.setValue({
      ...beneficiary,
      id: beneficiary.singleUse ? null : beneficiary.id,
    });
    this.saveBeneficiary.emit({ clientId: this.form.controls.clientId.value, beneficiary });
    if (beneficiary.id === null && beneficiary.singleUse) {
      this.cancelEditBeneficiary();
    }
  }

  onEditBeneficiaryError(errors: ValidationErrors | null) {
    this.editBeneficiaryValidationErrors = errors;
    this.form.controls.beneficiary.markAsTouched();
    setTimeout(() => {
      this.form.controls.beneficiary.updateValueAndValidity();
    });
  }

  private reloadBeneficiaries() {
    if (this.form.controls.beneficiary.value != null && !this.form.controls.beneficiary.value?.singleUse) {
      this.form.controls.beneficiary.setValue(null);
    }
    this.fetchBeneficiaries.emit(this.form.controls.clientId.value);
  }

  private selectFirstAccount() {
    this.form.controls.accountId.setValue(this.filteredAccounts && this.filteredAccounts.length > 0 ? this.filteredAccounts[0].id : '');
  }

}
