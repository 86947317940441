import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[rbIban]',
  standalone: true,
})
export class IbanDirective implements AfterViewChecked {

  private el: HTMLInputElement;

  constructor(private readonly elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterViewChecked(): void {
    this.formatIban(this.el.value);
  }

  @HostListener('blur', [
    '$event.target.value',
  ])
  onBlur(value: string) {
    this.formatIban(value);
  }

  private formatIban(value: string) {
    if (RegExp(/^[a-zA-Z]{2}\d/).exec(value)) {
      this.el.value = value.replaceAll(/ /g, '').match(/.{1,4}/g)?.join(' ').toUpperCase() ?? '';
    }
  }

}
