import {
  CommonModule,
  DatePipe,
}                           from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                           from '@angular/core';
import { MatIconModule }    from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule }  from '@ngx-translate/core';
import {
  AccountWithStatus,
  BankAccountStatus,
}                           from '~domain/types';

@Component({
  standalone: true,
  imports: [TranslateModule, DatePipe, MatIconModule, CommonModule, MatTooltipModule],
  selector: 'rb-bank-account-status',
  templateUrl: './bank-account-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountStatusComponent {
  @Input() bankAccount?: AccountWithStatus;
  @Input() large = false;

  protected readonly BankAccountStatus = BankAccountStatus;
}
