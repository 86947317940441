import { HttpClient }         from '@angular/common/http';
import {
  Inject,
  Injectable,
}                             from '@angular/core';
import { ENVIRONMENT }        from '~app/app.constants';
import { ShareResponse }      from '~domain/enums';
import { removeEmptyStrings } from '~utils/common';

@Injectable({
  providedIn: 'root',
})
export class RespondToDocumentApiService {
  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  respondToDocument(id: string, response: ShareResponse, clientId: string, message?: string) {
    const body = { response };
    if (message) {
      Object.assign(body, { message: message.trim() });
    }
    return this.http.patch<void>(`${this.environment.api}/clients/` + clientId + '/documents/' + id, removeEmptyStrings(body));
  }
}
