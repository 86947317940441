import { Injectable }       from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveToast,
  ToastrService,
}                           from 'ngx-toastr';

export enum Toast {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export type ToastOptions = {
  params?: Record<string, unknown>;
  persistent?: boolean;
  closeable?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
  ) {
  }

  show(type: Toast, key: string, options: ToastOptions = {}): ActiveToast<unknown> {
    const { params, persistent = false, closeable = true } = options;

    const translationPrefix = `TOAST.${type}.${key}`.toUpperCase();
    return this.toastrService.show(
      this.translateService.optional(`${translationPrefix}.MESSAGE`, params),
      this.translateService.instant(`${translationPrefix}.TITLE`, params),
      { disableTimeOut: persistent, tapToDismiss: closeable },
      `toast-${type}`,
    );
  }
}
