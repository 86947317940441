import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
}                               from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import {
  distinctUntilChanged,
  startWith,
  Subject,
  takeUntil,
}                               from 'rxjs';

@Directive({
  selector: '[rbStructuredRemittance]',
  standalone: true,
})
export class StructuredRemittanceDirective implements OnDestroy, OnInit {

  private el: HTMLInputElement;
  private destroy$ = new Subject<void>();

  constructor(private readonly elementRef: ElementRef,
              private formControlDirective: FormControlDirective) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.formControlDirective.valueChanges?.pipe(
      startWith(this.formControlDirective.value),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe(value => {
      const cursorPosition = this.el.selectionStart;
      this.formatRemittance(value);
      if (cursorPosition === 4 || cursorPosition === 9) {
        this.el.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      } else {
        this.el.setSelectionRange(cursorPosition, cursorPosition);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private formatRemittance(value: string) {
    const d = value.trim().replaceAll(/\D/g, '').slice(0, 12).split('');
    if (d.length > 3) {
      d.splice(3, 0, '/');
    }
    if (d.length > 8) {
      d.splice(8, 0, '/');
    }
    const newValue = `${d.slice(0, 14).join('')}`;
    if (value !== newValue) {
      this.formControlDirective.control.setValue(newValue);
    }
  }
}
