import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
}                             from '@angular/core';
import {
  NonNullableFormBuilder,
  ValidationErrors,
}                             from '@angular/forms';
import {
  distinctUntilChanged,
  Subject,
  takeUntil,
}                             from 'rxjs';
import { ViewChildDirective } from '~app/core';
import {
  BeneficiaryType,
  UpdateTransactionType,
}                             from '~domain/enums';
import { Beneficiary }        from '~domain/types';
import {
  CustomValidators,
  ValidatorService,
}                             from '../core/validators';

export type EditBeneficiaryRequest = {
  clientId: string,
  beneficiary: Beneficiary
}

@Component({
  selector: 'rb-edit-beneficiary',
  templateUrl: './edit-beneficiary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBeneficiaryComponent implements OnChanges, OnDestroy, OnInit {

  @Input({ required: true })
  beneficiary: Beneficiary | null = null;
  @Input()
  beneficiaryEditing: boolean | null = false;
  @Output()
  confirm = new EventEmitter<Beneficiary>();
  @Output()
  cancel = new EventEmitter<void>();
  @Output()
  validationErrors = new EventEmitter<ValidationErrors | null>();
  @ViewChild(ViewChildDirective)
  nameInput?: ViewChildDirective;

  form = this.fb.group({
    counterpartName: this.fb.control(''),
    counterpartReference: this.fb.control('', {
      asyncValidators: [CustomValidators.ibanValidator(this.validatorService)],
    }),
    type: this.fb.control<'single-use' | 'client' | 'global'>('single-use'),
  });
  protected readonly UpdateTransactionType = UpdateTransactionType;
  private destroy$ = new Subject<void>();

  constructor(private readonly fb: NonNullableFormBuilder,
              private readonly validatorService: ValidatorService) {
  }

  ngOnInit() {
    this.form.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(status => {
        if (status === 'VALID') {
          this.validationErrors.emit(null);
        } else if (!this.form.controls.counterpartName.valid) {
          this.validationErrors.emit(this.form.controls.counterpartName.errors);
        } else if (!this.form.controls.counterpartReference.valid) {
          this.validationErrors.emit(this.form.controls.counterpartReference.errors);
        }
      });
    this.nameInput?.focus();
  }

  ngOnChanges(): void {
    this.form.controls.counterpartName.setValue(this.beneficiary?.counterpartName || '');
    this.form.controls.counterpartReference.setValue(this.beneficiary?.counterpartReference || '');
    this.form.controls.type.setValue(this.beneficiary ? this.getType(this.beneficiary) : 'single-use');
    this.form.controls.counterpartReference.markAsTouched();
    setTimeout(() => {
      this.form.controls.counterpartReference.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  confirmEditBeneficiary() {
    if (this.form.valid) {
      const beneficiary: Beneficiary = {
        counterpartName: this.form.controls.counterpartName.value,
        counterpartReference: this.form.controls.counterpartReference.value,
        singleUse: this.form.controls.type.value === 'single-use',
        global: this.form.controls.type.value === 'global',
        type: BeneficiaryType.BENEFICIARY,
        id: this.beneficiary?.id || null,
      };
      this.confirm.emit(beneficiary);
    }
  }

  cancelEditBeneficiary() {
    this.cancel.emit();
  }

  private getType(beneficiary: Beneficiary) {
    if (beneficiary.singleUse) {
      return 'single-use';
    }
    return beneficiary.global ? 'global' : 'client';
  }
}
