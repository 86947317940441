import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'structuredRemittanceInfo',
})
export class StructuredRemittanceInfoPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    const trimmedValue = value.replaceAll(/[/+]/g, '');
    if (trimmedValue.length !== 12) {
      return value;
    }
    return '+++' + trimmedValue.substring(0, 3) + '/' + trimmedValue.substring(3, 7) + '/' + trimmedValue.substring(7) + '+++';
  }
}
