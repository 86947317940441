export enum FileExtension {
  BMP = 'bmp',
  BZ = 'bz',
  BZ2 = 'bz2',
  CSV = 'csv',
  DOC = 'doc',
  DOCM = 'docm',
  DOCX = 'docx',
  GIF = 'gif',
  GZ = 'gz',
  JPEG = 'jpeg',
  JPG = 'jpg',
  ODP = 'odp',
  ODS = 'ods',
  ODT = 'odt',
  PDF = 'pdf',
  PNG = 'png',
  PPT = 'ppt',
  PPTM = 'pptm',
  PPTX = 'pptx',
  RAR = 'rar',
  RTF = 'rtf',
  SVG = 'svg',
  TAR = 'tar',
  TIF = 'tif',
  TIFF = 'tiff',
  TXT = 'txt',
  XLS = 'xls',
  XLSM = 'xlsm',
  XLSX = 'xlsx',
  ZIP = 'zip',
  SevenZ = '7z',
}

export function fileIcon(extension: FileExtension) {
  switch (extension) {
    case FileExtension.BMP:
    case FileExtension.GIF:
    case FileExtension.JPEG:
    case FileExtension.JPG:
    case FileExtension.PNG:
    case FileExtension.SVG:
    case FileExtension.TIF:
    case FileExtension.TIFF:
      return 'image';
    case FileExtension.BZ:
    case FileExtension.BZ2:
    case FileExtension.GZ:
    case FileExtension.RAR:
    case FileExtension.TAR:
    case FileExtension.ZIP:
    case FileExtension.SevenZ:
      return 'file-archive';
    case FileExtension.CSV:
      return 'csv';
    case FileExtension.DOC:
    case FileExtension.DOCM:
    case FileExtension.DOCX:
    case FileExtension.ODT:
      return 'word';
    case FileExtension.ODP:
    case FileExtension.PPT:
    case FileExtension.PPTM:
    case FileExtension.PPTX:
      return 'powerpoint';
    case FileExtension.ODS:
    case FileExtension.XLS:
    case FileExtension.XLSM:
    case FileExtension.XLSX:
      return 'excel';
    case FileExtension.PDF:
      return 'pdf';
    case FileExtension.RTF:
    case FileExtension.TXT:
      return 'text-file';
  }
}

export function mimeType(extension: FileExtension): string {
  switch (extension) {
    case FileExtension.BMP:
      return 'image/bmp';
    case FileExtension.BZ:
      return 'application/x-bzip';
    case FileExtension.BZ2:
      return 'application/x-bzip2';
    case FileExtension.CSV:
      return 'text/csv';
    case FileExtension.DOC:
      return 'application/msword';
    case FileExtension.DOCM:
      return 'application/vnd.ms-word.document.macroenabled.12';
    case FileExtension.DOCX:
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case FileExtension.GIF:
      return 'image/gif';
    case FileExtension.GZ:
      return 'application/gzip';
    case FileExtension.JPEG:
    case FileExtension.JPG:
      return 'image/jpeg';
    case FileExtension.ODP:
      return 'application/vnd.oasis.opendocument.presentation';
    case FileExtension.ODS:
      return 'application/vnd.oasis.opendocument.spreadsheet';
    case FileExtension.ODT:
      return 'application/vnd.oasis.opendocument.text';
    case FileExtension.PDF:
      return 'application/pdf';
    case FileExtension.PNG:
      return 'image/png';
    case FileExtension.PPT:
      return 'application/vnd.ms-powerpoint';
    case FileExtension.PPTM:
      return 'application/vnd.ms-powerpoint.presentation.macroenabled.12';
    case FileExtension.PPTX:
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case FileExtension.RAR:
      return 'application/vnd.rar';
    case FileExtension.RTF:
      return 'application/rtf';
    case FileExtension.SVG:
      return 'image/svg+xml';
    case FileExtension.TAR:
      return 'application/x-tar';
    case FileExtension.TIF:
    case FileExtension.TIFF:
      return 'image/tiff';
    case FileExtension.TXT:
      return 'text/plain';
    case FileExtension.XLS:
      return 'application/vnd.ms-excel';
    case FileExtension.XLSM:
      return 'application/vnd.ms-excel.sheet.macroenabled.12';
    case FileExtension.XLSX:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case FileExtension.ZIP:
      return 'application/zip';
    case FileExtension.SevenZ:
      return 'application/x-7z-compressed';
  }
}

// Windows does not follow standard (application/zip) as mime type for zip files
export const supportedMimeTypes = [...Object.values(FileExtension).map(mimeType), 'application/x-zip-compressed'];

export function needsBrandColor(extension: FileExtension) {
  switch (fileIcon(extension)) {
    case 'csv':
    case 'file-archive':
    case 'image':
    case 'text-file':
      return true;
    case 'excel':
    case 'powerpoint':
    case 'pdf':
    case 'word':
      return false;
  }
}
