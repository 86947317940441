import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import {
  Beneficiary,
  PaymentAccount,
  PaymentData,
  SimpleClient,
}                      from '~domain/types';
import {
  datesToStrings,
  removeEmptyStrings,
}                      from '~utils/common';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  fetchClients() {
    return this.http.get<SimpleClient[]>(`${this.environment.api}/clients-to-link`);
  }

  fetchBeneficiaries(clientId: string) {
    return this.http.get<Beneficiary[]>(`${this.environment.api}/clients/${clientId}/beneficiaries`);
  }

  fetchAccounts() {
    return this.http.get<PaymentAccount[]>(`${this.environment.api}/payment-accounts`);
  }

  addPayment(paymentData: PaymentData) {
    const { clientId, ...payment } = paymentData;
    const payments = [removeEmptyStrings(datesToStrings(payment))];
    return this.http.post<void>(`${this.environment.api}/clients/${clientId}/batch/payments`, { payments });
  }

  updatePayment(paymentData: PaymentData) {
    const { id, ...payment } = paymentData;
    return this.http.put<void>(`${this.environment.api}/payments/${id}`, removeEmptyStrings(datesToStrings(payment)));
  }

  updateBeneficiary(beneficiaryId: string, clientId: string, beneficiary: Beneficiary) {
    return this.http.put<void>(`${this.environment.api}/clients/${clientId}/beneficiaries/${beneficiaryId}`, beneficiary);
  }

  addBeneficiary(clientId: string, beneficiary: Beneficiary) {
    return this.http.post<void>(`${this.environment.api}/clients/${clientId}/beneficiaries`, beneficiary);
  }

  deleteBeneficiary(beneficiaryId: string, clientId: string) {
    return this.http.delete<void>(`${this.environment.api}/clients/${clientId}/beneficiaries/${beneficiaryId}`);
  }
}
