<div class="h-full w-full absolute bg-login bg-cover"></div>
<div class="h-full w-full absolute flex justify-center items-center bg-primary bg-opacity-50">
  <mat-card class="w-[600px] p-[2rem] bg-primary bg-opacity-65 backdrop-blur-lg rounded-[2rem] shadow-card">
    <div class="flex justify-center items-center mt-[1rem] mb-[2rem] h-[4rem]">
      <img alt="roov-logo" class="h-[4rem]" src="assets/img/roov-logo.svg" />
    </div>
    <mat-card-header class="p-[1rem]">
      <mat-card-title class="w-full text-center text-lg text-primary">{{ 'LOGIN.HEADER' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-[1rem]">
      <form (ngSubmit)="onSubmit()" [formGroup]="form" class="flex flex-col gap-y-[1rem]">
        <mat-form-field class="with-error">
          <mat-icon matPrefix svgIcon="profile"></mat-icon>
          <input autocomplete="on"
                 formControlName="username"
                 matInput
                 placeholder="{{ 'LOGIN.FORM.FIELD.USERNAME.PLACEHOLDER' | translate }}"
                 type="text" />
          <mat-error>
            {{ form.controls.username | error: 'LOGIN.FORM.FIELD.USERNAME.ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="with-error">
          <mat-icon matPrefix svgIcon="key"></mat-icon>
          <input autocomplete="on"
                 formControlName="password"
                 matInput
                 placeholder="{{ 'LOGIN.FORM.FIELD.PASSWORD.PLACEHOLDER' | translate }}"
                 type="password" />
          <mat-error>
            {{ form.controls.password | error: 'LOGIN.FORM.FIELD.PASSWORD.ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="flex">
          <div *ngrxLet="colorMode$ as colorMode" class="flex gap-[10px] py-[0.5rem]">
            <mat-icon (click)="setColorMode('light')"
                      [ngClass]="colorMode === 'light' ? 'icon-secondary' : 'icon-tertiary'"
                      class="icon-20 cursor-pointer hover:icon-secondary"
                      matListItemIcon
                      matTooltip="{{ 'NAVBAR.COLOR_MODE.light' | translate }}"
                      svgIcon="sun"></mat-icon>
            <mat-icon (click)="setColorMode('dark')"
                      [ngClass]="colorMode === 'dark' ? 'icon-secondary' : 'icon-tertiary'"
                      class="icon-20 cursor-pointer hover:icon-secondary"
                      matListItemIcon
                      matTooltip="{{ 'NAVBAR.COLOR_MODE.dark' | translate }}"
                      svgIcon="moon"></mat-icon>
            <mat-icon (click)="setColorMode(null)"
                      [ngClass]="colorMode === null ? 'icon-secondary' : 'icon-tertiary'"
                      class="icon-20 cursor-pointer hover:icon-secondary"
                      matListItemIcon
                      matTooltip="{{ 'NAVBAR.COLOR_MODE.system' | translate }}"
                      svgIcon="system"></mat-icon>
          </div>
          <div class="flex-1"></div>
          <button class="button-primary" mat-flat-button type="submit">
            <mat-icon svgIcon="sign-in"></mat-icon>
            {{ 'LOGIN.FORM.BUTTON.SUBMIT.LABEL' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
