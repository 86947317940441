import { Params } from '@angular/router';
import {
  isUndefined,
  omitBy,
}                 from 'lodash';

export type Sort = {
  property: string;
  direction: 'asc' | 'desc';
};

export type Sortable = {
  sort: Sort;
};

export function sortParamsComplete(queryParams: Params): boolean {
  return queryParams['sortProperty'] &&
    (queryParams['sortDirection'] === 'asc' || queryParams['sortDirection'] === 'desc');
}

export function sortParamsFromQueryParams(queryParams: Params, initialPrperty: string, initialDirection: 'asc' | 'desc'): Sortable {
  return {
    sort: {
      property: queryParams['sortProperty'] || initialPrperty,
      direction: queryParams['sortDirection'] || initialDirection,
    },
  };
}

/**
 * Creates (complete) query parameters from (complete) parameters with sort.
 *
 * @param params Parameters to create query parameters from.
 */
export function queryParamsFromParamsWithSort(params: Sortable) {
  const { sort, ...other } = params;
  return { sortProperty: sort?.property, sortDirection: sort?.direction, ...other };
}

/**
 * Creates query parameters from partial parameters with sort.
 *
 * This can be used when e.g. only client type filter is changed
 * to merge the corresponding clientType query parameter into the
 * existing query parameters.
 *
 * @param params Partial parameters to create query parameters from.
 */
export function partialQueryParamsFromParamsWithSort(params: Partial<Sortable>) {
  const { sort, ...other } = params;
  return omitBy({ sortProperty: sort?.property, sortDirection: sort?.direction, ...other }, isUndefined);
}
