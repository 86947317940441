<div *ngIf="bankAccount" class="flex">
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.ARCHIVED"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="'APP.BANK_ACCOUNTS.WARNINGS.ARCHIVED' | translate"
            class="icon-15 icon-secondary"
            svgIcon="archive"></mat-icon>
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.AUTH_INVALID"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="'APP.BANK_ACCOUNTS.WARNINGS.AUTH_INVALID' | translate"
            class="icon-15 icon-warning"
            color="warn"
            fontIcon="fa-triangle-exclamation"
            svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.EXPIRING"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="('APP.BANK_ACCOUNTS.WARNINGS.EXPIRING' | translate)
                          + ' ' + (bankAccount.expiresAt | date: 'dd/MM/yyyy')"
            class="icon-15 icon-warning"
            svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.MOVED_TO_ISABEL_ACTIVE
                   || bankAccount.status === BankAccountStatus.MOVED_TO_ISABEL_INACTIVE"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="'APP.BANK_ACCOUNTS.WARNINGS.MOVED_TO_ISABEL' | translate"
            class="icon-15 icon-warning"
            svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.TECHNICAL_FAILURE"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="'APP.BANK_ACCOUNTS.WARNINGS.TECHNICAL_FAILURE' | translate"
            class="icon-15 icon-warning"
            svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="bankAccount.status === BankAccountStatus.NOT_FOUND_IN_ISABEL"
            [class]="large ? 'icon-20' : 'icon-15'"
            [matTooltip]="'APP.BANK_ACCOUNTS.WARNINGS.NOT_FOUND_ISABEL' | translate"
            class="icon-15 icon-warning"
            svgIcon="warning"></mat-icon>
</div>
