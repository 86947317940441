export enum ClientType {
  DebtMediation = 'DEBT_MEDIATION',
  FinancialManagement = 'FINANCIAL_MANAGEMENT',
  SocialRent = 'SOCIAL_RENT',
  BudgetGuidance = 'BUDGET_GUIDANCE',
  Rent = 'RENT',
  PCSW = 'PCSW',
}

export function officeAccountsEnabledForClientType(clientType: ClientType) {
  return [ClientType.DebtMediation, ClientType.FinancialManagement].includes(clientType);
}
