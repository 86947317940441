import { ClientType } from '../enums';
import {
  BankAccount,
  ClientPersonalInfoFormData,
}                     from './';

export type Client = {
  id: string;
  firstName: string;
  lastName?: string;
  email?: string | null;
  type: ClientType;
  types: ClientType[];
  municipality?: string | null;
  lastNotificationDate?: Date | null;
  numberOfNotifications: number;
  bankAccounts: BankAccount[];
  archivedAt?: Date | null;
};

export type SimpleClient = {
  id: string;
  firstName: string;
  lastName?: string;
  type: ClientType;
  types: ClientType[];
  municipality?: string;
}

export type ClientData = {
  type: ClientType;
  personalInfoData: ClientPersonalInfoFormData;
  initialAccountId?: string;
};
