import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
}                     from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  map,
  Observable,
}                     from 'rxjs';

const DATE_STRING = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?)?$/;

@Injectable()
export class DeserializationInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(httpEvent => {
        if (httpEvent instanceof HttpResponse) {
          parseIsoDates(httpEvent.body);
        }
        return httpEvent;
      }),
    );
  }
}

function parseIsoDates(object: any) {
  if (!object || typeof object !== 'object') {
    return object;
  }
  Object.entries(object)
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => {
      if (typeof value === 'string' && DATE_STRING.test(value)) {
        object[key] = new Date(value);
      } else if (Array.isArray(value)) {
        value.forEach(v => parseIsoDates(v));
      } else if (typeof value === 'object') {
        parseIsoDates(value);
      }
    });
}
