import {
  Pipe,
  PipeTransform,
}                          from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  isEmpty,
  snakeCase,
}                          from 'lodash';

@Pipe({
  standalone: true,
  name: 'error',
  pure: false,
})
export class ErrorPipe implements PipeTransform {
  transform(control: AbstractControl, prefix = ''): string {
    const { errors } = control;
    if (!isEmpty(errors)) {
      const key = snakeCase(Object.keys(errors)[0]).toUpperCase();
      return prefix ? `${prefix}.${key}` : key;
    }
    return '';
  }
}
