import { Injectable }   from '@angular/core';
import { Store }        from '@ngrx/store';
import { ClientData }   from '~domain/types';
import { createClient } from '../state/app.actions';
import {
  selectCreateClientIsLoading,
  selectOffice,
}                       from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class AddClientService {
  office$ = this.store.select(selectOffice);
  createClientIsLoading$ = this.store.select(selectCreateClientIsLoading);

  constructor(private readonly store: Store) {
  }

  createClient(client: ClientData) {
    return this.store.dispatch(createClient({ client }));
  }
}
