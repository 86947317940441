<rb-dialog (buttonClick)="onDialogButtonClick($event)" [config]="dialogConfig">
  <form [formGroup]="form">
    <rb-edit-payment (fetchBeneficiaries)="fetchBeneficiaries($event)"
                     (saveBeneficiary)="saveBeneficiary($event)"
                     [addPaymentEnabled]="!request.id"
                     [addPaymentLoading]="addPaymentLoading$ | async"
                     [beneficiariesLoading]="beneficiariesLoading$ | async"
                     [beneficiaries]="beneficiaries$ | async"
                     [beneficiaryEditing]="beneficiaryEditing$ | async"
                     [errorKey$]="errorKey$"
                     [paymentAccounts]="paymentAccounts$ | async"
                     [paymentClients]="paymentClients$ | async"
                     formControlName="payment">
    </rb-edit-payment>
  </form>
</rb-dialog>
