import { Moment } from 'moment';

export type TransactionFilters = {
  counterpartName?: string,
  counterpartReference?: string,
  categories?: string[],
  tagIds?: string[]
} & DateFilter & TypeFilter & AmountFilter & RemittanceInfoFilter;

export type CategoryFilter = {
  mainCategoryName?: string,
  subCategoryName?: string,
  uncategorized: boolean
}

export type DateFilter = {
  executionDateFrom?: Moment,
  executionDateTo?: Moment,
}

export type TypeFilter = {
  description?: string,
}

export type AmountFilter = {
  amountMin?: number,
  amountMax?: number,
}

export type RemittanceInfoFilter = {
  remittanceInformation?: string,
}

export type CategoryFilterWithLabel = {
  label?: string
} & CategoryFilter;

export type CounterpartFilter = {
  counterpartType: 'NAME' | 'REFERENCE',
  counterpart?: string
}
