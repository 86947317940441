import {
  HttpBackend,
  HttpClientModule,
}                           from '@angular/common/http';
import { NgModule }         from '@angular/core';
import { LocalHttpBackend } from '~app/http/local/http-backend';
import { environment }      from '~environment';

@NgModule({
  providers: [!environment.production && !environment.api ? { provide: HttpBackend, useClass: LocalHttpBackend } : []],
  exports: [HttpClientModule],
})
export class HttpModule {
}
