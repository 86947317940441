import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
}                             from '@angular/core';
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
}                             from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDatepickerToggleIcon,
}                             from '@angular/material/datepicker';
import {
  MatFormField,
  MatSuffix,
}                             from '@angular/material/form-field';
import { MatIcon }            from '@angular/material/icon';
import { MatInput }           from '@angular/material/input';
import { TranslateModule }    from '@ngx-translate/core';
import { Moment }             from 'moment';
import { DatePipe }           from '../pipes';
import { FilterComponent }    from './filter.component';
import { FormFieldComponent } from './form-field.component';

export type DateRangeFilter = {
  dateFrom: Moment | null,
  dateTo: Moment | null,
}

@Component({
  selector: 'rb-date-range-filter',
  standalone: true,
  imports: [
    DatePipe,
    FilterComponent,
    FormFieldComponent,
    FormsModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepickerToggleIcon,
    MatFormField,
    MatIcon,
    MatInput,
    MatSuffix,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './date-range-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFilterComponent implements OnChanges {

  @Input({ required: true })
  filter = {} as DateRangeFilter;
  @Input()
  disabled = false;
  @Input()
  title?: string;
  @Output()
  filterSet = new EventEmitter<DateRangeFilter>();
  @Output()
  filterCleared = new EventEmitter<void>();

  form = this.fb.group({
    dateFrom: this.fb.control<Moment | null>(null),
    dateTo: this.fb.control<Moment | null>(null),
  });

  constructor(
    private readonly fb: NonNullableFormBuilder,
  ) {
  }

  ngOnChanges(): void {
    this.form.controls.dateFrom.setValue(this.filter.dateFrom);
    this.form.controls.dateTo.setValue(this.filter.dateTo);
  }

  setFilter() {
    const dateFrom = this.form.controls.dateFrom.value;
    const dateTo = this.form.controls.dateTo.value;
    this.filterSet.emit({ dateFrom, dateTo });
  }

  clearFilter() {
    this.filterCleared.emit();
  }

  getLabel() {
    if (this.filter.dateFrom !== null && this.filter.dateTo !== null) {
      return 'APP.FILTERS.DATE.VALUE.FROM_TO';
    }
    if (this.filter.dateTo !== null) {
      return 'APP.FILTERS.DATE.VALUE.TO';
    }
    if (this.filter.dateFrom !== null) {
      return 'APP.FILTERS.DATE.VALUE.FROM';
    }
    return '';
  }

}
