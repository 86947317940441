import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { Observable }  from 'rxjs';
import {
  Credentials,
  LoginResult,
}                      from '~domain/types';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  login(credentials: Credentials): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${this.environment.api}/login`, credentials);
  }
}
