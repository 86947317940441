import { HTTP_INTERCEPTORS }                from '@angular/common/http';
import {
  ErrorHandler,
  NgModule,
}                                           from '@angular/core';
import { ReactiveFormsModule }              from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
}                                           from '@angular/material-moment-adapter';
import { MatBadgeModule }                   from '@angular/material/badge';
import { MatButtonModule }                  from '@angular/material/button';
import { MatCardModule }                    from '@angular/material/card';
import { MatCheckboxModule }                from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
}                                           from '@angular/material/core';
import { MatDatepickerModule }              from '@angular/material/datepicker';
import { MatDialogModule }                  from '@angular/material/dialog';
import { MatDividerModule }                 from '@angular/material/divider';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
}                                           from '@angular/material/form-field';
import {
  MatIconModule,
  MatIconRegistry,
}                                           from '@angular/material/icon';
import { MatInputModule }                   from '@angular/material/input';
import { MatListModule }                    from '@angular/material/list';
import { MatPaginatorIntl }                 from '@angular/material/paginator';
import { MatProgressSpinnerModule }         from '@angular/material/progress-spinner';
import { MatRadioModule }                   from '@angular/material/radio';
import { MatSelectModule }                  from '@angular/material/select';
import { MatSidenavModule }                 from '@angular/material/sidenav';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule,
}                                           from '@angular/material/tooltip';
import {
  DomSanitizer,
  SafeResourceUrl,
}                                           from '@angular/platform-browser';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';
import {
  Router,
  RouterModule,
}                                           from '@angular/router';
import { ServiceWorkerModule }              from '@angular/service-worker';
import { LetDirective }                     from '@ngrx/component';
import { EffectsModule }                    from '@ngrx/effects';
import { StoreRouterConnectingModule }      from '@ngrx/router-store';
import { StoreModule }                      from '@ngrx/store';
import { StoreDevtoolsModule }              from '@ngrx/store-devtools';
import {
  TranslateModule,
  TranslateService,
}                                           from '@ngx-translate/core';
import * as Sentry                          from '@sentry/angular-ivy';
import { ToastrModule }                     from 'ngx-toastr';
import { Debug }                            from '~debug';
import { environment }                      from '~environment';
import {
  BankAccountDetailsComponent,
  ClientDetailsComponent,
  DialogComponent,
  EditPersonalInfoComponent,
  FormFieldComponent,
  FormSectionComponent,
}                                           from '~shared/components';
import {
  ErrorPipe,
  SplitPipe,
}                                           from '~shared/pipes';
import { AddClientDialogComponent }         from './add-client/add-client-dialog.component';
import { AppComponent }                     from './app.component';
import {
  httpErrorConfig,
  matDateFormats,
  ngrxEffectsConfig,
  ngrxStoreConfig,
  ngrxStoreDevToolsConfig,
  ngxToastrConfig,
  ngxTranslateConfig,
  routerConfig,
  sentryErrorHandlerConfig,
  serviceWorkerConfig,
}                                           from './app.config';
import {
  ENVIRONMENT,
  HTTP_ERROR_CONFIG,
  LOCAL_STORAGE,
  LOCATION,
}                                           from './app.constants';
import {
  IbanDirective,
  StructuredRemittanceDirective,
  ViewChildDirective,
}                                           from './core';
import {
  AuthenticationInterceptor,
  DeserializationInterceptor,
  HttpErrorInterceptor,
}                                           from './core/interceptors';
import { RBPaginatorIntl }                  from './core/paginator-intl';
import { HttpModule }                       from './http/http.module';
import { LoginComponent }                   from './login/login.component';
import { MainComponent }                    from './main/main.component';
import { EditBeneficiaryComponent }         from './payment/edit-beneficiary.component';
import { EditPaymentComponent }             from './payment/edit-payment.component';
import { PaymentDialogComponent }           from './payment/payment-dialog.component';
import { RespondToDocumentDialogComponent } from './respond-to-document/respond-to-document-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    AddClientDialogComponent,
    RespondToDocumentDialogComponent,
    PaymentDialogComponent,
    EditPaymentComponent,
    EditBeneficiaryComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ServiceWorkerModule.register(...serviceWorkerConfig),
    RouterModule.forRoot(...routerConfig),
    TranslateModule.forRoot(...ngxTranslateConfig),
    ToastrModule.forRoot(...ngxToastrConfig),
    StoreModule.forRoot(...ngrxStoreConfig),
    EffectsModule.forRoot(...ngrxEffectsConfig),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument(...ngrxStoreDevToolsConfig),
    HttpModule,
    ReactiveFormsModule,
    ErrorPipe,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    DialogComponent,
    EditPersonalInfoComponent,
    MatRadioModule,
    LetDirective,
    FormSectionComponent,
    MatProgressSpinnerModule,
    MatSelectModule,
    ClientDetailsComponent,
    FormFieldComponent,
    BankAccountDetailsComponent,
    SplitPipe,
    StructuredRemittanceDirective,
    MatDatepickerModule,
    IbanDirective,
    ViewChildDirective,
    MatCheckboxModule,
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: LOCATION, useValue: window.location },
    { provide: LOCAL_STORAGE, useValue: window.localStorage },
    { provide: HTTP_ERROR_CONFIG, useValue: httpErrorConfig },
    { provide: HTTP_INTERCEPTORS, useClass: DeserializationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: matDateFormats },
    { provide: MatPaginatorIntl, useClass: RBPaginatorIntl },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler(...sentryErrorHandlerConfig) },
    { provide: Sentry.TraceService, deps: [Router], useFactory: (router: Router) => new Sentry.TraceService(router) },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private domSanitizer: DomSanitizer;

  constructor(
    domSanitizer: DomSanitizer,
    matIconRegistry: MatIconRegistry,
    router: Router,
    translateService: TranslateService,
  ) {
    this.domSanitizer = domSanitizer;
    matIconRegistry
      .addSvgIcon('accounts', this.iconPath('Accounts'))
      .addSvgIcon('actions', this.iconPath('Actions'))
      .addSvgIcon('archive', this.iconPath('Archive'))
      .addSvgIcon('archived-accounts', this.iconPath('Archived accounts'))
      .addSvgIcon('archived-clients', this.iconPath('Archived clients'))
      .addSvgIcon('arrow-down', this.iconPath('Arrow down'))
      .addSvgIcon('budget-management', this.iconPath('Budget management'))
      .addSvgIcon('calendar', this.iconPath('Calendar'))
      .addSvgIcon('check', this.iconPath('Check'))
      .addSvgIcon('chevron-down', this.iconPath('Chevron down'))
      .addSvgIcon('chevron-left', this.iconPath('Chevron left'))
      .addSvgIcon('chevron-right', this.iconPath('Chevron right'))
      .addSvgIcon('chevron-up', this.iconPath('Chevron up'))
      .addSvgIcon('clients', this.iconPath('Clients'))
      .addSvgIcon('contacts', this.iconPath('Contacts'))
      .addSvgIcon('copy', this.iconPath('Copy'))
      .addSvgIcon('counterpart-client', this.iconPath('Counterpart - client'))
      .addSvgIcon('counterpart-global', this.iconPath('Counterpart - global'))
      .addSvgIcon('counterpart-single-use', this.iconPath('Counterpart - single use'))
      .addSvgIcon('cross', this.iconPath('Cross'))
      .addSvgIcon('csv', this.iconPath('CSV'))
      .addSvgIcon('dashboard', this.iconPath('Dashboard'))
      .addSvgIcon('debt-mediation', this.iconPath('Debt mediation'))
      .addSvgIcon('delete', this.iconPath('Delete'))
      .addSvgIcon('document', this.iconPath('Document'))
      .addSvgIcon('download', this.iconPath('Download'))
      .addSvgIcon('drag-handle', this.iconPath('Drag handle'))
      .addSvgIcon('edit', this.iconPath('Edit'))
      .addSvgIcon('error', this.iconPath('Error'))
      .addSvgIcon('euro', this.iconPath('Euro'))
      .addSvgIcon('excel', this.iconPath('Excel'))
      .addSvgIcon('export', this.iconPath('Export'))
      .addSvgIcon('external-link', this.iconPath('External link'))
      .addSvgIcon('file-archive', this.iconPath('File archive'))
      .addSvgIcon('filter', this.iconPath('Filter'))
      .addSvgIcon('guardianship', this.iconPath('Guardianship'))
      .addSvgIcon('hidden', this.iconPath('Hidden'))
      .addSvgIcon('image', this.iconPath('Image'))
      .addSvgIcon('incoming', this.iconPath('Incoming'))
      .addSvgIcon('info-circle', this.iconPath('Info - circle'))
      .addSvgIcon('info-clear', this.iconPath('Info - clear'))
      .addSvgIcon('key', this.iconPath('Key'))
      .addSvgIcon('link', this.iconPath('Link'))
      .addSvgIcon('messages', this.iconPath('Messages'))
      .addSvgIcon('moon', this.iconPath('Moon'))
      .addSvgIcon('more-horizontal', this.iconPath('More - horizontal'))
      .addSvgIcon('more-vertical', this.iconPath('More - vertical'))
      .addSvgIcon('notifications', this.iconPath('Notifications'))
      .addSvgIcon('office', this.iconPath('Office'))
      .addSvgIcon('office-account', this.iconPath('Office account'))
      .addSvgIcon('outgoing', this.iconPath('Outgoing'))
      .addSvgIcon('payment-error', this.iconPath('Payment - error'))
      .addSvgIcon('payment-processed', this.iconPath('Payment - processed'))
      .addSvgIcon('payment-processing', this.iconPath('Payment - processing'))
      .addSvgIcon('payment-queued', this.iconPath('Payment - queued'))
      .addSvgIcon('payment-warning', this.iconPath('Payment - warning'))
      .addSvgIcon('payments', this.iconPath('Payments'))
      .addSvgIcon('pcsw', this.iconPath('PCSW'))
      .addSvgIcon('pdf', this.iconPath('PDF'))
      .addSvgIcon('percentage', this.iconPath('Percentage'))
      .addSvgIcon('plus', this.iconPath('Plus'))
      .addSvgIcon('powerpoint', this.iconPath('Powerpoint'))
      .addSvgIcon('priority', this.iconPath('Priority'))
      .addSvgIcon('profile', this.iconPath('Profile'))
      .addSvgIcon('question', this.iconPath('Question'))
      .addSvgIcon('refresh', this.iconPath('Refresh'))
      .addSvgIcon('remittance-structured', this.iconPath('Remittance - structured'))
      .addSvgIcon('remittance-unstructured', this.iconPath('Remittance - unstructured'))
      .addSvgIcon('rent', this.iconPath('Rent'))
      .addSvgIcon('repeat', this.iconPath('Repeat'))
      .addSvgIcon('search', this.iconPath('Search'))
      .addSvgIcon('send', this.iconPath('Send'))
      .addSvgIcon('settings', this.iconPath('Settings'))
      .addSvgIcon('share', this.iconPath('Share'))
      .addSvgIcon('sign-in', this.iconPath('Sign in'))
      .addSvgIcon('sign-out', this.iconPath('Sign out'))
      .addSvgIcon('social-rent', this.iconPath('Social rent'))
      .addSvgIcon('stop', this.iconPath('Stop'))
      .addSvgIcon('sun', this.iconPath('Sun'))
      .addSvgIcon('system', this.iconPath('System'))
      .addSvgIcon('tags', this.iconPath('Tags'))
      .addSvgIcon('text-file', this.iconPath('Text file'))
      .addSvgIcon('upload', this.iconPath('Upload'))
      .addSvgIcon('visible', this.iconPath('Visible'))
      .addSvgIcon('warning', this.iconPath('Warning'))
      .addSvgIcon('word', this.iconPath('Word'));
    router.events.subscribe(Debug.navigation);
    translateService.use(navigator.language);
  }

  private iconPath(name: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`);
  }
}
