<div class="flex flex-row gap-[0.5rem] justify-between">
  <div class="flex-1 flex-col overflow-hidden">
    <div class="font-bold">{{ notification.title }}</div>
    <div *ngIf="notification.expanded || notification.subNotifications.length === 0"
         [class.truncate]="!notification.expanded"
         [class.whitespace-break-spaces]="notification.expanded"
         class="text-sm">
      <span class="text-secondary">{{ notification.dateCreated | date }}:</span> {{ notification.description }}
    </div>
    <div *ngIf="notification.expanded && notification.details" [innerHTML]="notification.details" class="text-sm"></div>
    <div *ngIf="notification.document && notification.expanded" class="flex flex-col">
      <div
        (click)="doDownloadDocument()"
        class="rounded-[0.5rem] px-[1rem] py-[0.5rem] my-[1rem] bg-secondary hover:bg-tertiary group flex gap-[0.5rem] cursor-pointer">
        <mat-icon class="icon-20" [class.icon-brand]="needsBrandColor(notification.document.extension)"
                  [svgIcon]="fileIcon(notification.document.extension)"></mat-icon>
        <div class="truncate flex-1">{{ notification.document.name }}.{{ notification.document.extension }}</div>
        <mat-icon *ngIf="notification.document.id !== documentDownloadingId"
                  class="icon-20 icon-tertiary group-hover:icon-secondary"
                  svgIcon="download"></mat-icon>
        <mat-spinner *ngIf="notification.document.id === documentDownloadingId"
                     class="spinner-brand"
                     diameter="20"></mat-spinner>
      </div>
      <div *ngIf="!notification.document.shareResponse" class="flex justify-end gap-[2rem] px-[1rem]">
        <button (click)="doRespondToDocument(ShareResponse.DECLINED)"
                class="button-negative"
                mat-button>
          <mat-icon svgIcon="cross"></mat-icon>
          {{ 'APP.CLIENTS.CLIENT.TAB.NOTIFICATIONS.TABLE.COLUMN.NOTIFICATION.DECLINED' | translate }}
        </button>
        <button (click)="doRespondToDocument(ShareResponse.ACCEPTED)"
                class="button-positive"
                mat-button>
          <mat-icon svgIcon="check"></mat-icon>
          {{ 'APP.CLIENTS.CLIENT.TAB.NOTIFICATIONS.TABLE.COLUMN.NOTIFICATION.ACCEPTED' | translate }}
        </button>
      </div>
    </div>
    <div *ngFor="let subNotification of notification.subNotifications; let last = last">
      <div *ngIf="notification.expanded || last"
           [class]="'text-sm ' + (notification.expanded ?
                       'whitespace-break-spaces' : 'truncate')">
        <span class="text-secondary">{{ subNotification.dateCreated | date }}:</span>
        {{ subNotification.description }}
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-end">
    <button (click)="doToggle()" class="button-secondary" mat-icon-button>
      <mat-icon *ngIf="notification.expanded" class="icon-15" svgIcon="chevron-up"></mat-icon>
      <mat-icon *ngIf="!notification.expanded" class="icon-15" svgIcon="chevron-down"></mat-icon>
    </button>
  </div>
</div>
