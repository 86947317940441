export type DateGroup = {
  date: Date;
}

export function groupByDate<S>(array: S[], fn: (x: S) => Date) {
  const groupedArray = array.reduce((group: Map<number, S[]>, element) => {
    const key = fn(element).valueOf();
    if (!group.get(key)) {
      group.set(key, [element]);
    } else {
      group.get(key)?.push(element);
    }
    return group;
  }, new Map<number, S[]>());
  const result: (S | DateGroup)[] = [];
  groupedArray.forEach((elements, key) => {
    result.push({ date: new Date(key) });
    result.push(...elements);
  });
  return result;
}

export function isGroup(index: number, item: unknown): boolean {
  return (item as DateGroup).date !== undefined;
}
