export enum NotificationType {
  NOT_ENOUGH_FUNDS = 'NOT_ENOUGH_FUNDS',
  BALANCE_EXCEEDED_THRESHOLD = 'BALANCE_EXCEEDED_THRESHOLD',
  INCOME_DETECTED = 'INCOME_DETECTED',
  INSUFFICIENT_BALANCE_EXPECTED = 'INSUFFICIENT_BALANCE_EXPECTED',
  UNEXPECTED_INCOME_LOSS = 'UNEXPECTED_INCOME_LOSS',
  RECURRING_EXPENSE_MISSING = 'RECURRING_EXPENSE_MISSING',
  NEW_RECURRING_EXPENSES = 'NEW_RECURRING_EXPENSES',
  DOCUMENT_PAYMENT_REQUEST = 'DOCUMENT_PAYMENT_REQUEST',
  YEARLY_REPORT_DUE = 'YEARLY_REPORT_DUE',
  FINAL_REPORT_DUE = 'FINAL_REPORT_DUE',
}
