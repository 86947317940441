import {
  Pipe,
  PipeTransform,
}                           from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'number',
})
export class NumberPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {
  }

  transform(number: number | null | undefined, digits: number | undefined = 2): string | null {
    if (number === null || number === undefined) {
      return null;
    }

    const locale = this.translateService.currentLang;

    return Number(number).toLocaleString(locale, {
      minimumIntegerDigits: 1,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }
}
