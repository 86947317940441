import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  isNumber,
  isString,
  repeat,
} from 'lodash';

@Pipe({
  standalone: true,
  name: 'redact',
})
export class RedactPipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    options: {
      retain?: number | { front: number; back: number };
      replace?: string | { value: string; type: 'each' | 'all' };
    } = {},
  ): string {
    if (!value) {
      return '';
    }

    const { retain = 0, replace = '*' } = options;
    const retainCount = isNumber(retain) ? { front: retain, back: retain } : retain;
    const redact = isString(replace) ? { value: replace, type: 'each' } : replace;

    const redactCount = value.length - retainCount.front - retainCount.back;
    const redactedValue = redact.type === 'each' ? repeat(redact.value, redactCount) : redact.value;

    const prefix = retainCount.front ? value.slice(0, retainCount.front) : '';
    const suffix = retainCount.back ? value.slice(-retainCount.back) : '';

    return `${prefix}${redactedValue}${suffix}`;
  }
}
