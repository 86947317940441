import { Injectable }          from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
}                              from '@angular/forms';
import { of }                  from 'rxjs';
import { ValidatorApiService } from './validator-api.service';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {

  constructor(private readonly validatorApiService: ValidatorApiService) {
  }

  isValidIban(iban?: string) {
    if (iban && iban.trim().length > 0) {
      return this.validatorApiService.isValidIban(iban);
    }
    return of(true);
  }

  errorMatches(control: FormControl, errorKey: string | null) {
    return !!(control.errors && errorKey && control.errors[errorKey]);
  }

  firstErrorKey(group: FormGroup) {
    return this._firstErrorKey(group);
  }

  private _firstErrorKey(group: FormGroup | FormArray): string | null {
    if (group.errors) {
      return Object.keys(group.errors)[0];
    }
    for (const controlKey in group.controls) {
      const control = group.get(controlKey);
      let error = null;
      if (control instanceof FormGroup || control instanceof FormArray) {
        error = this._firstErrorKey(control);
      } else if (control?.errors) {
        error = Object.keys(control.errors)[0];
      }
      if (error) {
        return error;
      }
    }
    return null;
  }
}
