import { Params } from '@angular/router';

export type Page<T> = {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
};

export type Pageable = {
  page: number;
  size: number;
};

export function pageParamsComplete(queryParams: Params): boolean {
  return queryParams['page'] && !isNaN(+queryParams['page']) &&
    queryParams['size'] && !isNaN(+queryParams['size']);
}

export function pageParamsFromQueryParams(queryParams: Params, initialPage: number, initialSize: number): Pageable {
  return {
    page: (queryParams['page'] && !isNaN(+queryParams['page'])) ? +queryParams['page'] : initialPage,
    size: (queryParams['size'] && !isNaN(+queryParams['size'])) ? +queryParams['size'] : initialSize,
  };
}
