import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
}                     from '@angular/common/http';
import {
  Inject,
  Injectable,
}                     from '@angular/core';
import { Observable } from 'rxjs';
import {
  ACCESS_TOKEN,
  AUTHORIZATION_HEADER,
  LOCAL_STORAGE,
}                     from '../../app.constants';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      request = request.clone({
        headers: request.headers.set(AUTHORIZATION_HEADER, `Bearer ${accessToken}`),
      });
    }
    return next.handle(request);
  }
}
