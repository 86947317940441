import {
  Inject,
  Injectable,
}                   from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {
  filter,
  from,
  map,
  switchMap,
}                   from 'rxjs';
import {
  ENVIRONMENT,
  LOCATION,
}                   from '../app.constants';
import {
  Toast,
  ToastService,
}                   from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    @Inject(LOCATION) private readonly location: Location,
    private readonly swUpdate: SwUpdate,
    private readonly toastService: ToastService,
  ) {
  }

  checkForUpdate(): void {
    if (this.environment.production) {
      from(this.swUpdate.checkForUpdate())
        .pipe(
          filter(Boolean),
          map(() => this.toastService.show(Toast.Info, 'UPDATE_AVAILABLE', { persistent: true })),
          switchMap(toast => toast.onTap),
        )
        .subscribe(() => this.location.reload());
    }
  }
}
