import { getCurrencySymbol } from '@angular/common';
import {
  BankAccountOwnerType,
  BankAccountType,
}                            from '../enums';
import { SimpleClient }      from './';

export type BankAccount = {
  id: string,
  ownerType: BankAccountOwnerType;
  type: BankAccountType;
  logoUrl?: string | null;
  label?: string | null;
  iban: string;
  balance?: number | null;
  currency?: string | null;
  financialInstitutionName?: string | null;
  supportPayments: boolean;
} & AccountWithStatus & AccountWithApiProduct;

export type BankAccountToLink = {
  iban: string;
  label?: string;
  logoUrl?: string;
  financialInstitutionName?: string;
};

export type MultiCurrencyAccount = {
  position: number;
  type?: BankAccountType;
  iban: string;
  label?: string;
  logoUrl?: string;
  financialInstitutionName?: string;
  linked?: boolean;
};

export type AccountsByClient = {
  officeAccounts: MultiCurrencyAccount[];
  clientAccounts: MultiCurrencyAccount[];
};

export type OfficeAccount = {
  id: string,
  iban: string;
  currency: string,
  financialInstitutionName: string
  logoUrl: string,
  label?: string;
  balance?: number,
  client?: SimpleClient,
  synchronizedAt?: Date,
} & AccountWithStatus & AccountWithApiProduct;

export type AccountWithStatus = {
  status: BankAccountStatus,
  expiresAt?: Date,
  archivedAt?: Date,
};

export type AccountWithApiProduct = {
  apiProduct?: ApiProduct,
};

export type OfficeAccountFilters = {
  archived: boolean;
  onlyWithWarning: boolean;
  linked: boolean | null;
  financialInstitutionId: string | null;
};

export enum ApiProduct {
  ISABEL = 'ISABEL',
  XS2A = 'XS2A',
}

export enum BankAccountStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  AUTH_INVALID = 'AUTH_INVALID',
  TECHNICAL_FAILURE = 'TECHNICAL_FAILURE',
  MOVED_TO_ISABEL_ACTIVE = 'MOVED_TO_ISABEL_ACTIVE',
  MOVED_TO_ISABEL_INACTIVE = 'MOVED_TO_ISABEL_INACTIVE',
  EXPIRING = 'EXPIRING',
  NOT_FOUND_IN_ISABEL = 'NOT_FOUND_IN_ISABEL',
}

export type BankAccountDetails = {
  id: string,
  iban: string,
  currency: string,
  financialInstitutionName: string,
  logoUrl: string,
  clientSettings?: BankAccountClientSettings,
  label?: string,
  currentBalance?: number,
  availableBalance?: number,
  supportPayments: boolean,
  restorable: boolean,
  synchronizedAt?: Date,
} & AccountWithStatus & AccountWithApiProduct;

export type BankAccountClientSettings = {
  client: SimpleClient,
  type: BankAccountType,
  ownerType: BankAccountOwnerType,
  showIncomingTransactions: boolean | null,
  showOutgoingTransactions: boolean | null,
  showBalance: boolean | null,
  transactionsShared: boolean | null,
  balanceShared: boolean | null
};

export type EditBankAccountClientSettingsData = {
  clientId?: string,
  type: BankAccountType,
  showIncomingTransactions: boolean,
  showOutgoingTransactions: boolean,
  showBalance: boolean,
};

export const euroSymbol = getCurrencySymbol('EUR', 'wide');

export type PaymentAccount = {
  id: string,
  clientId: string,
  iban: string;
  currency: string,
  logoUrl: string,
  label?: string;
  currentBalance?: number,
  type: BankAccountType,
  position: number,
}
