<ng-container *ngIf="config">
  <div *ngIf="config.title || config.closeable"
       class="px-[2rem] pt-[1rem] pb-[0.9rem] flex items-center justify-between border-b border-b-medium mb-0
              bg-tertiary h-[5rem] text-lg"
       mat-dialog-title>
    <span *ngIf="config.title">{{ config.title | translate : config.titleTranslateArgs }}</span>
    <button (click)="close()" *ngIf="config.closeable" class="button-secondary" mat-icon-button>
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <mat-dialog-content cdkScrollable
                      class="{{ config.compact ? 'w-[50rem]' : 'w-[100rem] min-h-[55rem]' }}
                       max-h-[calc(100vh-20rem)] flex-1 p-[2rem] text-base text-primary">
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="config.buttons.length > 0"
                      class="px-[2rem] pt-[0.9rem] pb-[1rem] flex flex-nowrap border-t border-t-medium gap-x-[1rem]
                             bg-tertiary min-h-0 h-[5rem] max-w-[100rem]">
    <mat-error *ngIf="config.errorKey$ | async as errorKey"
               class="shrink truncate">{{ fullErrorKey(errorKey) | translate }}
    </mat-error>
    <div class="flex-1"></div>
    <button (click)="onDialogButtonClick(button)"
            *ngFor="let button of config.buttons"
            [class.button-negative]="button.style === 'negative'"
            [class.button-primary]="button.style === 'primary'"
            [class.button-secondary]="button.style === 'secondary'"
            [disabled]="button.disabled$ | async"
            class="shrink-0"
            mat-flat-button>
      <mat-icon *ngIf="button.icon && (button.isLoading$ | async) !== true" [svgIcon]="button.icon"></mat-icon>
      <mat-spinner *ngIf="button.icon && (button.isLoading$ | async) === true" diameter="15"></mat-spinner>
      <span>{{ button.label | translate }}</span>
    </button>
  </mat-dialog-actions>
</ng-container>
