import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
}                            from '@angular/common/http';
import {
  Inject,
  Injectable,
  Optional,
}                            from '@angular/core';
import { Router }            from '@angular/router';
import { get }               from 'lodash';
import {
  catchError,
  Observable,
  throwError,
}                            from 'rxjs';
import { HTTP_ERROR_CONFIG } from '../../app.constants';
import {
  Toast,
  ToastService,
}                            from '../toast.service';

export type Redirect = { route: string; withOrigin?: string };
export type HttpErrorConfig = {
  identity?: string;
  redirects?: Record<string | number, Redirect>;
  registry?: Record<string | number, string | null>;
};

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Optional()
    @Inject(HTTP_ERROR_CONFIG)
    private readonly config: HttpErrorConfig | null,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const { status, error } = httpErrorResponse;
        const { identity = '', redirects = {}, registry = {} } = this.config || {};
        const key = get(error, identity);

        const redirect = redirects[key] || redirects[status];
        if (redirect) {
          const { route, withOrigin } = redirect;
          const state = withOrigin ? { [withOrigin]: this.router.url } : {};
          this.router.navigate([route], { state });
        } else {
          const entry = key in registry ? registry[key] : registry[status];
          if (entry !== null) {
            this.toastService.show(Toast.Error, entry || 'UNKNOWN');
          }
        }

        return throwError(() => httpErrorResponse);
      }),
    );
  }
}
