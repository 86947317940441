import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { isEmpty }     from 'lodash';
import {
  map,
  Observable,
}                      from 'rxjs';
import { ClientData }  from '~domain/types';
import {
  datesToStrings,
  removeEmptyStrings,
}                      from '~utils/common';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class AddClientApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  createClient(data: ClientData): Observable<string> {
    const { street, number, postalCode, municipality: city, ...body } = data.personalInfoData;
    Object.assign(body, { type: data.type, initialAccountId: data.initialAccountId });
    const address = removeEmptyStrings({ street, number, postalCode, city });
    if (!isEmpty(address)) {
      Object.assign(body, { address });
    }
    return this.http.post(`${this.environment.api}/clients`, removeEmptyStrings(datesToStrings(body)), { responseType: 'text' })
      .pipe(map(id => id.replaceAll('"', '')));
  }
}
