import {
  Pipe,
  PipeTransform,
}                          from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isEmpty }         from 'lodash';

@Pipe({
  standalone: true,
  name: 'errorParam',
  pure: false,
})
export class ErrorParamPipe implements PipeTransform {
  transform(control: AbstractControl): string {
    const { errors } = control;
    if (!isEmpty(errors)) {
      const value = Object.values(errors)[0];
      return typeof value === 'string' ? value : '';
    }
    return '';
  }
}
