import { HttpClient }         from '@angular/common/http';
import {
  Inject,
  Injectable,
}                             from '@angular/core';
import { map }                from 'rxjs';
import { ValidationResponse } from '~domain/types';
import { ENVIRONMENT }        from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class ValidatorApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  isValidIban(iban: string) {
    return this.http.get<ValidationResponse>(`${this.environment.api}/iban-validations/${iban}`)
      .pipe(map(response => response.valid));
  }
}
