import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { Observable }  from 'rxjs';
import { User }        from '~domain/types';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  fetchUser(): Observable<User> {
    return this.http.get<User>(`${this.environment.api}/profile`);
  }
}
