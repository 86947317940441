import { CommonModule }        from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
}                              from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule }  from '@angular/material/form-field';
import { MatIconModule }       from '@angular/material/icon';
import { MatInputModule }      from '@angular/material/input';
import {
  MatListModule,
  MatSelectionList,
  MatSelectionListChange,
}                              from '@angular/material/list';
import { MatSelectModule }     from '@angular/material/select';
import { TranslateModule }     from '@ngx-translate/core';
import { FilterComponent }     from './filter.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    MatSelectModule,
    FilterComponent,
    MatFormFieldModule,
    MatListModule,
  ],
  selector: 'rb-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectFilterComponent<T> implements OnChanges {
  @Input({ required: true })
  values: T[] | null = [];
  @Input({ required: true })
  valuesSet: string[] | null = [];
  @Input()
  disabled = false;
  @Input({ required: true })
  valueLabel = '';
  @Input({ required: true })
  label = '';
  @Input({ required: true })
  templateRef: TemplateRef<{ item: T }> = {} as TemplateRef<{ item: T }>;
  @Input()
  filter?: (value: T, filter: string) => boolean | undefined;
  valuesToSet: string[] = [];
  @ViewChild(MatSelectionList)
  matSelectionList?: MatSelectionList;
  @Output()
  filterSet = new EventEmitter<string[]>();
  @Output()
  filterCleared = new EventEmitter<void>();
  filteredValues: T[] = [];
  value = '';

  @Input({ required: true })
  optionValue: (value: T) => string = (value: T) => '';

  ngOnChanges(): void {
    this.filterValues(this.value);
    this.valuesToSet = this.valuesSet || [];
    this.matSelectionList?.writeValue(this.valuesToSet);
  }

  filterValues(value: string) {
    if (!this.values) {
      this.filteredValues = [];
    } else if (this.filter === undefined || value.trim().length === 0) {
      this.filteredValues = this.values;
    } else {
      this.filteredValues = this.values.filter(val => this.filter!(val, value.trim().toLowerCase()));
    }
  }

  selectFilter() {
    if (this.valuesToSet.length > 0) {
      this.filterSet.emit(this.valuesToSet);
    } else {
      this.clearFilter();
    }
  }

  clearFilter() {
    this.filterCleared.emit();
  }

  selectionChange(listChange: MatSelectionListChange) {
    listChange.options.forEach(option => {
      if (option.selected) {
        this.valuesToSet = [...this.valuesToSet, option.value];
      } else {
        this.valuesToSet = this.valuesToSet.filter(value => value !== option.value);
      }
    });
  }
}
