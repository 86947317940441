<rb-dialog (buttonClick)="onDialogButtonClick($event)" [config]="dialogConfig">
  <form [formGroup]="form" class="flex flex-col gap-y-[4rem]">
    <rb-form-section [background]="false"
                     [border]="false"
                     [title]="'APP.CLIENTS.CLIENT_NOTIFICATIONS.RESPOND_TO_DOCUMENT.NOTIFICATION' | translate">
      <div class="text-sm">
        <span class="text-secondary">{{ request.notification.dateCreated | date }}:</span>
        {{ request.notification.description }}
      </div>
      <div (click)="downloadDocument(document.id, document.name, document.extension)"
           *ngIf="request.notification.document as document"
           class="rounded-[0.5rem] px-[1rem] py-[0.5rem] my-[1rem] bg-secondary hover:bg-tertiary group flex gap-[0.5rem] cursor-pointer w-[43rem]">
        <mat-icon [class.icon-brand]="needsBrandColor(document.extension)"
                  [svgIcon]="fileIcon(document.extension)"
                  class="no-hover icon-20"></mat-icon>
        <div class="truncate flex-1">{{ document.name }}</div>
        <mat-icon *ngIf="document.id !== (documentDownloadingId$ |async)"
                  class="icon-20 icon-tertiary group-hover:icon-secondary"
                  svgIcon="download"></mat-icon>
        <mat-spinner *ngIf="document.id === (documentDownloadingId$ |async)"
                     class="spinner-brand"
                     diameter="20"></mat-spinner>
      </div>
    </rb-form-section>
    <rb-form-section [title]="'APP.CLIENTS.CLIENT_NOTIFICATIONS.RESPOND_TO_DOCUMENT.RESPONSE' | translate">
      <mat-radio-group class="flex" formControlName="response">
        <div *ngFor="let option of responseOptions"
             [ngClass]="form.controls.response.value === option ? 'text-primary' : 'text-tertiary'"
             class="flex-1 flex flex-col items-center">
          <mat-radio-button [value]="option" class="vertical" labelPosition="before">
            <div class="flex flex-col items-center gap-y-[1rem] hover:cursor-pointer">
              <mat-icon [class.icon-negative]="form.controls.response.value === ShareResponse.DECLINED && form.controls.response.value === option"
                        [class.icon-positive]="form.controls.response.value === ShareResponse.ACCEPTED && form.controls.response.value === option"
                        [class.icon-tertiary]="form.controls.response.value !== option"
                        [svgIcon]="option === ShareResponse.ACCEPTED ? 'check' : 'cross'"
                        class="icon-40"></mat-icon>
              <div [class.text-secondary]="form.controls.response.value !== option">
                {{ 'APP.CLIENTS.CLIENT.TAB.NOTIFICATIONS.TABLE.COLUMN.NOTIFICATION.' + option  | translate }}
              </div>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </rb-form-section>
    <rb-form-section [background]="false"
                     [border]="false"
                     [title]="'APP.CLIENTS.CLIENT_NOTIFICATIONS.RESPOND_TO_DOCUMENT.MESSAGE.TITLE' | translate">
      <mat-form-field class="w-full multi-line">
        <textarea cdkAutosizeMinRows="3"
                  cdkTextareaAutosize
                  formControlName="message"
                  matInput
                  placeholder="{{ 'APP.CLIENTS.CLIENT_NOTIFICATIONS.RESPOND_TO_DOCUMENT.MESSAGE.PLACEHOLDER' |
                  translate }}"></textarea>
      </mat-form-field>
    </rb-form-section>
  </form>
</rb-dialog>
