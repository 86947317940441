import { Moment }         from 'moment';
import { SettlementType } from '../enums';
import {
  CreditorFormData,
  JusticePeace,
  LabourCourt,
}                         from './';

export type GenericDossier = {
  startDate?: Date,
  endDate?: Date,
  caseIdentifier?: string,
};

export type GenericDossierFormData = {
  startDate: Moment | null,
  endDate: Moment | null,
  caseIdentifier: string,
};

export type FinancialManagementDossier = {
  startDate?: Date,
  endDate?: Date,
  caseIdentifier?: string,
  docketNumber?: string,
  balanceThreshold?: number,
  justicePeace?: JusticePeace,
};

export type FinancialManagementDossierFormData = {
  startDate: Moment | null,
  endDate: Moment | null,
  caseIdentifier: string,
  docketNumber: string,
  balanceThreshold: number | null,
  justicePeaceId: string | null,
};

export type DebtMediationDossier = {
  startDate?: Date,
  endDate?: Date,
  caseIdentifier?: string,
  admissibilityDateSettlement?: Date,
  homologationDateSettlement?: Date,
  docketNumber?: string,
  labourCourt?: LabourCourt,
  totalDebt?: number,
  mainSum?: number,
  interest?: number,
  costs?: number,
  courtCosts?: number,
  increases?: number,
  monthlyExpectedIncome?: number,
  monthlyExpectedExpenses?: number,
  retainedAmount?: number,
  settlementType?: SettlementType,
  settlementAmount?: number,
  settlementPercentage?: number,
  monthlyPaymentsPeriodicity?: number,
  paymentStartDate?: Date,
  paymentEndDate?: Date,
};

export type DebtMediationDossierFormData = {
  startDate: Moment | null,
  endDate: Moment | null,
  caseIdentifier: string,
  admissibilityDateSettlement: Moment | null,
  homologationDateSettlement: Moment | null,
  docketNumber: string,
  labourCourtId: string | null,
  totalDebt: number | null,
  mainSum: number | null,
  interest: number | null,
  costs: number | null,
  courtCosts: number | null,
  increases: number | null,
  monthlyExpectedIncome: number | null,
  monthlyExpectedExpenses: number | null,
  retainedAmount: number | null,
  settlementType: SettlementType,
  settlementAmount: number | null,
  settlementPercentage: number | null,
  monthlyPaymentsPeriodicity: number | null,
  paymentStartDate: Moment | null,
  paymentEndDate: Moment | null,
  creditors: CreditorFormData[];
};

export type ClientDossier = GenericDossier | DebtMediationDossier | FinancialManagementDossier;

export type ClientDossierFormData =
  GenericDossierFormData
  | DebtMediationDossierFormData
  | FinancialManagementDossierFormData;
