import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(value: string | null | undefined, options: { size: number; separator?: string }): string {
    if (!value) {
      return '';
    }

    const { size, separator = ' ' } = options;

    return value.match(new RegExp(`.{1,${size}}`, 'g'))?.join(separator) || '';
  }
}
