import { Moment } from 'moment';
import {
  FileExtension,
  RbDocumentType,
}                 from '../enums';

export namespace RbDocumentForm {
  export type Type = {
    type: RbDocumentType;
    extension: FileExtension,
    disabled: boolean,
  };

  export type Info = {
    bankAccountSelection: AccountSelection;
    officeAccountIds: string[];
    clientAccountIds: string[];
    supportedPeriods: PeriodType[];
    defaultCustom?: Period;
    defaultMonthly?: Period;
    defaultQuarterly?: Period;
    defaultYearly?: Period;
    selectablePeriod?: Period;
  };

  export enum AccountSelection {
    None = 'NONE',
    Single = 'SINGLE',
    Multiple = 'MULTIPLE',
    MultipleSplit = 'MULTIPLE_SPLIT',
  }

  export enum PeriodType {
    Custom = 'CUSTOM',
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY',
    Yearly = 'YEARLY',
  }

  export type Period = {
    startDate: Date;
    endDate: Date;
  };

  export type Data = {
    type: RbDocumentType | null;
    accounts: AccountsFormData,
    periodWithType: PeriodWithTypeFormData;
  };

  export type AccountsFormData = {
    officeAccountIds: string[];
    clientAccountIds: string[];
  };

  export type PeriodWithTypeFormData = {
    type: PeriodType | null,
    period: PeriodFormData | null;
  }

  export type PeriodFormData = {
    startDate: Moment | null;
    endDate: Moment | null;
  }

  export function defaultPeriod(type: PeriodType, info: Info) {
    switch (type) {
      case RbDocumentForm.PeriodType.Custom:
        return info.defaultCustom;
      case RbDocumentForm.PeriodType.Monthly:
        return info.defaultMonthly;
      case RbDocumentForm.PeriodType.Quarterly:
        return info.defaultQuarterly;
      case RbDocumentForm.PeriodType.Yearly:
        return info.defaultYearly;
    }
  }
}
