<div class="flex gap-[0.5rem] items-stretch">
  <div class="flex-1">
    <ng-content></ng-content>
  </div>
  <div class="flex flex-col justify-between">
    <button (click)="onUp()" [disabled]="first" class="button-secondary" mat-icon-button>
      <mat-icon class="icon-15" svgIcon="chevron-up"></mat-icon>
    </button>
    <button (click)="onDelete()" [disabled]="deleteDisabled" class="button-secondary" mat-icon-button>
      <mat-icon class="icon-15" svgIcon="cross"></mat-icon>
    </button>
    <button (click)="onDown()" [disabled]="last" class="button-secondary" mat-icon-button>
      <mat-icon class="icon-15" svgIcon="chevron-down"></mat-icon>
    </button>
  </div>
</div>
