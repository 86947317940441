import { FormControl }    from '@angular/forms';
import {
  isNil,
  round,
}                         from 'lodash';
import moment, { Moment } from 'moment';

export function pluck<V, K extends keyof V>(property: K): (value: NonNullable<V>) => V[K];
export function pluck<V, K extends keyof NonNullable<V>>(
  property: K,
): (value: V | null | undefined) => NonNullable<V>[K] | undefined;

export function pluck(property: string) {
  return (value: Record<string, unknown> | null | undefined) => value?.[property];
}

export function using<T, R>(arg: NonNullable<T>, fn: (arg: T) => R): R;
export function using<T, R>(arg: T | null | undefined, fn: (arg: T) => R): R | undefined;
export function using(arg: unknown, fn: (arg: unknown) => unknown) {
  return isNil(arg) ? undefined : fn(arg);
}

export function truthy<T>(value: T | false | '' | 0 | null | undefined): value is T {
  return !!value;
}

export function abort(message?: string): never {
  throw new Error(message);
}

export function removeEmptyStrings(obj: any) {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }
  const clone = { ...obj };
  Object.entries(clone).forEach(([key, val]) => {
    if (val === '') {
      delete clone[key];
    } else if (Array.isArray(val)) {
      val.forEach((item, index, array) => {
        array[index] = removeEmptyStrings(item);
      });
    } else if (typeof val === 'object' && val) {
      clone[key] = removeEmptyStrings(val);
    }
  });
  return clone;
}

export function dateOnlyISOString(date: Date | Moment) {
  return date.toISOString().split('T')[0];
}

export function datesToStrings(obj: any) {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }
  const clone = { ...obj };
  Object.entries(clone).forEach(([key, val]) => {
    if (val && (val instanceof Date || moment.isMoment(val))) {
      clone[key] = dateOnlyISOString(val);
    } else if (Array.isArray(val)) {
      clone[key] = val.map(
        item => (item instanceof Date || moment.isMoment(item)) ? dateOnlyISOString(item) : datesToStrings(item),
      );
    } else if (typeof val === 'object' && val) {
      clone[key] = datesToStrings(val);
    }
  });
  return clone;
}

export function toArray<T>(object: T | T[]): T[] {
  if (object instanceof Array) {
    return object;
  }
  return [object];
}

/**
 * Determines if value has its first occurrence in array at index.
 *
 * Can be used as argument of Array.filter() to only keep unique values.
 *
 * e.g. [1, 2, 2, 1, 3].filter(isFirstOccurrence) will result in [1, 2, 3]
 */
export function isFirstOccurrence<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

/**
 * Rounds the value of a numeric form control, updates the form value and also returns the new value.
 *
 * @param control The control to round the value of
 * @param roundOptions Options for rounding
 * * `precision`: The precision to round to (defaults to 2)
 * * `onlyPositive`: When true, negative values are considered to be zero (defaults to false)
 * * `clearZero`: When true, the form value is emptied if rounded value is zero (defaults to true)
 */
export function roundControlValue(control: FormControl<number | null>,
                                  roundOptions?: {
                                    precision?: number;
                                    clearZero?: boolean;
                                  }) {
  const precision = roundOptions?.precision ?? 2;
  const clearZero = roundOptions?.clearZero ?? true;
  const sign = ((control.value || 0) < 0 ? -1 : 1);

  // 'round' is not handling negative numbers as we want it.
  // * round(-1.001, 2) results in -1.01 i.s.o. -1.00
  // * round(-1.005, 2) results in -1.00 i.s.o. -1.01
  // Therefore, we make negative number positive, round them and then make them negative again.
  let roundedValue: number | null = round(sign * round(sign * (control.value || 0), precision), precision);
  if (clearZero && roundedValue === 0) {
    roundedValue = null;
  }

  control.setValue(roundedValue);

  return roundedValue;
}
