import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
}                  from '@angular/core';
import { map }     from 'rxjs';
import packageJson from '../../package.json';
import {
  ColorModeService,
  UpdateService,
}                  from './core';

@Component({
  selector: 'rb-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @HostBinding('attr.app-version')
  readonly appVersion = packageJson.version;

  darkModeEnabled$ = this.colorModeService.colorMode$.pipe(
    map(colorMode => colorMode === 'dark' || (!colorMode && window.matchMedia('(prefers-color-scheme: dark)').matches)),
  );

  constructor(
    private readonly colorModeService: ColorModeService,
    private readonly updateService: UpdateService,
  ) {
  }

  ngOnInit() {
    this.colorModeService.fetchColorMode();
  }

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event) {
    const { visibilityState } = event.target as Document;
    if (visibilityState === 'visible') {
      this.updateService.checkForUpdate();
    }
  }
}
