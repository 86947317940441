import {
  Inject,
  Injectable,
}                          from '@angular/core';
import {
  Observable,
  tap,
}                          from 'rxjs';
import {
  Credentials,
  LoginResult,
}                          from '~domain/types';
import {
  ACCESS_TOKEN,
  LOCAL_STORAGE,
}                          from '../app.constants';
import { LoginApiService } from './login-api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly loginApiService: LoginApiService,
  ) {
  }

  login(credentials: Credentials): Observable<LoginResult> {
    return this.loginApiService
      .login(credentials)
      .pipe(tap(({ token }) => this.localStorage.setItem(ACCESS_TOKEN, token)));
  }
}
