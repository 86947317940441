import {
  Directive,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[rbViewChild]',
  standalone: true,
})
export class ViewChildDirective {
  private el: ElementRef;

  constructor(el: ElementRef) {
    this.el = el;
  }

  focus() {
    this.el.nativeElement.focus();
  }
}
