import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

@Component({
  selector: 'rb-view-column',
  standalone: true,
  imports: [],
  templateUrl: './view-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewColumnComponent {
}
