import {
  Pipe,
  PipeTransform,
}                           from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'date',
})
export class DatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {
  }

  transform(date: Date | null | undefined, options: { labels?: boolean; time?: boolean } = {}): string {
    if (!date) {
      return '';
    }

    const { labels, time } = options;
    const locale = this.translateService.currentLang;
    const dateStringOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const dateString = date.toLocaleDateString(locale, dateStringOptions);
    const timeStringOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: undefined };
    const timeString = time ? `, ${date.toLocaleTimeString(locale, timeStringOptions)}` : '';

    if (labels) {
      const today = new Date();
      if (dateString === today.toLocaleDateString(locale, dateStringOptions)) {
        return this.translateService.instant('DATE.TODAY') + timeString;
      }
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dateString === yesterday.toLocaleDateString(locale, dateStringOptions)) {
        return this.translateService.instant('DATE.YESTERDAY') + timeString;
      }
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      if (dateString === tomorrow.toLocaleDateString(locale, dateStringOptions)) {
        return this.translateService.instant('DATE.TOMORROW') + timeString;
      }
    }

    return dateString + timeString;
  }
}
