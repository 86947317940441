import { Injectable }       from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { min }              from 'lodash';
import { Subject }          from 'rxjs';

@Injectable()
export class RBPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = this.translateService.instant('PAGINATOR.FIRST_PAGE_LABEL');
  itemsPerPageLabel = this.translateService.instant('PAGINATOR.ITEMS_PER_PAGE_LABEL');
  lastPageLabel = this.translateService.instant('PAGINATOR.LAST_PAGE_LABEL');

  nextPageLabel = this.translateService.instant('PAGINATOR.NEXT_PAGE_LABEL');
  previousPageLabel = this.translateService.instant('PAGINATOR.PREVIOUS_PAGE_LABEL');

  constructor(private readonly translateService: TranslateService) {
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translateService.instant('PAGINATOR.EMPTY_RANGE_LABEL');
    }
    const firstItem = page * pageSize + 1;
    const lastItem = min([(page + 1) * pageSize, length]);
    return `${firstItem}-${lastItem} ${this.translateService.instant('PAGINATOR.RANGE_LABEL_OF')} ${length}`;
  }
}
