import { AsyncPipe }       from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
}                          from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule }   from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'rb-sortable-array-element',
  templateUrl: './sortable-array-element.component.html',
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableArrayElementComponent {

  @Input()
  first = false;
  @Input()
  last = false;
  @Input()
  deleteDisabled = false;
  @Output()
  up = new EventEmitter<void>();
  @Output()
  down = new EventEmitter<void>();
  @Output()
  delete = new EventEmitter<void>();

  onUp() {
    this.up.emit();
  }

  onDown() {
    this.down.emit();
  }

  onDelete() {
    this.delete.emit();
  }

}
