import { Language } from '../enums';

export type User = {
  id: string;
  language: Language;
  officeId: string;
};

export type OfficeUser = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
}

export type AccountUserFormData = {
  firstName: string,
  lastName: string,
  email: string,
}
