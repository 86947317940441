import { Directive }        from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { matDateFormats }   from '../app.config';

@Directive({
  selector: '[rbQuarterPicker]',
  standalone: true,
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'Q-YYYY' },
        display: { ...matDateFormats, dateInput: 'Q, YYYY' },
      },
    },
  ],
})
export class QuarterPickerDirective {

  constructor() {
  }

}
