// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  matchesUrl(urlSegments: string[]): boolean;
}

String.prototype.matchesUrl = function(this: string, urlSegments: [string]): boolean {
  if (!this.startsWith('/')) return false;
  const segments = this.split('?')[0].split('/').slice(1);
  return segments.length === urlSegments.length &&
    segments.every((value: string, index: number) => value === urlSegments[index]);
};
