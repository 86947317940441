import { Event } from '@angular/router';

enum DebugFlag {
  None = 0,
  Navigation = 1 << 0,
  Verbose = ~(~0 << 1),
}

let flags = DebugFlag.None;

export function enableDebugMode() {
  flags = DebugFlag.Verbose;
}

export namespace Debug {
  const _style = 'color: steelblue;font-weight: 900;';

  export function navigation(event: Event) {
    if ((flags & DebugFlag.Navigation) === DebugFlag.Navigation) {
      const tag = '[NAVIGATION]';
      const type = event.constructor.name.replace('Navigation', '');
      if (['Start', 'End', 'Cancel', 'Error'].includes(type)) {
        console.groupCollapsed('%c%s %s', _style, tag, type);
        console.log(event);
        console.groupEnd();
      }
    }
  }
}
