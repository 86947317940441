import { Injectable }      from '@angular/core';
import { Store }           from '@ngrx/store';
import {
  fetchColorMode,
  setColorMode,
}                          from '../state/app.actions';
import { selectColorMode } from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class ColorModeService {
  colorMode$ = this.store.select(selectColorMode);

  constructor(private readonly store: Store) {
  }

  fetchColorMode() {
    this.store.dispatch(fetchColorMode());
  }

  setColorMode(colorMode: 'dark' | 'light' | null) {
    this.store.dispatch(setColorMode({ colorMode }));
  }
}
