import {
  AfterViewChecked,
  Directive,
  ElementRef,
}                           from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { upperFirst }       from 'lodash';
import { matDateFormats }   from '../app.config';

@Directive({
  selector: '[rbMonthPicker]',
  standalone: true,
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'MM-YYYY' },
        display: { ...matDateFormats.display, dateInput: 'MMMM, YYYY' },
      },
    },
  ],
})
export class MonthPickerDirective implements AfterViewChecked {

  private el: HTMLInputElement;

  constructor(private readonly elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterViewChecked() {
    this.el.value = upperFirst(this.el.value);
  }
}
