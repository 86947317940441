<div [class.border-dashed]="value === undefined"
     [class.hover:opacity-100]="!disabled"
     [class.opacity-30]="disabled"
     [class.opacity-50]="value === undefined"
     class="flex rounded-full border border-dark text-secondary h-[2.5rem] items-center">
  <div *ngIf="!value"
       [class.cursor-pointer]="!disabled"
       [matMenuTriggerFor]="disabled ? null : menu"
       class="flex gap-x-[0.5rem] items-center px-[0.5rem]">
    <mat-icon *ngIf="!value" class="icon-15 icon-secondary" svgIcon="plus"></mat-icon>
    <div>{{ labelShort || label }}</div>
  </div>
  <div *ngIf="value" [matMenuTriggerFor]="disabled ? null : menu" class="flex">
    <div (click)="unsetFilter()"
         [class.cursor-pointer]="!disabled"
         [class.hover:bg-secondary]="!disabled"
         class="flex items-center gap-x-[0.5rem] px-[0.5rem] my-[1px] rounded-l-full">
      <mat-icon class="icon-15 icon-secondary" svgIcon="cross"></mat-icon>
      <div>{{ labelShort || label }}</div>
    </div>
    <mat-divider *ngIf="value" class="border-dark h-[2.5rem]" vertical="true"></mat-divider>
    <div [class.cursor-pointer]="!disabled"
         [class.hover:bg-secondary]="!disabled"
         class="flex items-center gap-x-[0.5rem] px-[0.5rem] my-[1px] rounded-r-full">
      <div class="text-brand">{{ value }}</div>
      <mat-icon class="icon-15 icon-brand" svgIcon="edit"></mat-icon>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <div (click)="$event.stopPropagation()" class="flex flex-col">
      <div class="font-bold text-primary p-[1rem] border-b border-b-medium">
        {{ 'APP.FILTER.TITLE' | translate }} {{ label.toLowerCase() }}
      </div>
      <div class="px-[1rem] py-[0.5rem] text-primary">
        <ng-content></ng-content>
      </div>
      <div class="flex justify-end p-[1rem] border-t border-t-medium">
        <button (click)="setFilter()" class="button-primary" mat-button>
          <mat-icon svgIcon="check"></mat-icon>
          {{ 'APP.FILTER.SET' | translate }}
        </button>
      </div>
    </div>
  </mat-menu>
</div>
