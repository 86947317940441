import {
  HttpBackend,
  HttpEvent,
  HttpRequest,
  HttpResponse,
}                     from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  delay,
  from,
  Observable,
  of,
  throwError,
}                     from 'rxjs';
import { using }      from '~utils/common';
import httpBackend    from './http-backend.json';

@Injectable()
export class LocalHttpBackend extends HttpBackend {
  handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    const method = request.method.toLowerCase();
    if (request.url.startsWith('/assets')) {
      return from(fetch(request.url)
        .then(response =>
          response.blob().then(blob =>
            blob.text().then(txt =>
              new HttpResponse({
                body: txt,
                status: response.status,
              }),
            ),
          ),
        ));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const match = (httpBackend as any)[method].find(({ endpoint }: any) => request.url.match(endpoint));
    return (
      using(match, ({ response }) => (of(new HttpResponse(response)) ||
        throwError(() => new Error(`${this.constructor.name} Found no stub response for ${method} ${request.url}`))).pipe(delay(300)))
    );
  }
}
