import { Moment }   from 'moment';
import { Language } from '../enums';
import { Address }  from './';

export type ClientInfo = {
  firstName: string;
  lastName?: string;
  address?: Address;
  phone?: string;
  email?: string;
  ssn?: string;
  birthDate?: Date;
  firstNamePartner?: string;
  lastNamePartner?: string;
  phonePartner?: string;
  emailPartner?: string;
  children?: string;
  remarks?: string;
  language: Language;
};

export type ClientInfoFormData = {
  personalInfoData: ClientPersonalInfoFormData;
  firstNamePartner: string;
  lastNamePartner: string;
  phonePartner: string;
  emailPartner: string;
  children: string;
  remarks: string;
};

export type ClientPersonalInfoFormData = {
  firstName: string;
  lastName: string;
  street: string;
  number: string;
  postalCode: string;
  municipality: string;
  phone: string;
  email: string;
  ssn: string;
  birthDate: Moment | null;
  language: Language | null;
};
