import { BeneficiaryType } from '../enums';

export type Beneficiary = {
  id: string | null;
  global: boolean;
  counterpartName?: string;
  counterpartReference?: string;
  type: BeneficiaryType;
  singleUse?: boolean;
}
