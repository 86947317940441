import { Routes }         from '@angular/router';
import { AppRoute }       from './app.route.enum';
import { LoginComponent } from './login/login.component';
import { MainComponent }  from './main/main.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: AppRoute.Dashboard,
      },
      {
        path: AppRoute.Dashboard,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: AppRoute.Clients,
        loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: AppRoute.Messages,
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: AppRoute.Contacts,
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
      },
      {
        path: AppRoute.BankAccounts,
        loadChildren: () => import('./bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
      },
      {
        path: AppRoute.Payments,
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: AppRoute.Notifications,
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: AppRoute.Actions,
        loadChildren: () => import('./actions/actions.module').then(m => m.ActionsModule),
      },
      {
        path: AppRoute.Archive,
        loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
      },
      {
        path: AppRoute.Settings,
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },
  {
    path: AppRoute.Login,
    component: LoginComponent,
  },
];
