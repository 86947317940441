import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
}                             from '@angular/core';
import {
  NonNullableFormBuilder,
  Validators,
}                             from '@angular/forms';
import { Router }             from '@angular/router';
import { isEmpty }            from 'lodash';
import { LOGIN_REDIRECT_URL } from '../app.constants';
import { ColorModeService }   from '../core';
import { LoginService }       from './login.service';

@Component({
  selector: 'rb-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  colorMode$ = this.colorModeService.colorMode$;

  form = this.fb.group({
    username: this.fb.control('', { validators: [Validators.required, Validators.email], updateOn: 'submit' }),
    password: this.fb.control('', { validators: [Validators.required], updateOn: 'submit' }),
  });

  private readonly redirectUrl: string;

  constructor(
    private readonly colorModeService: ColorModeService,
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly fb: NonNullableFormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.redirectUrl = this.router.getCurrentNavigation()?.extras?.state?.[LOGIN_REDIRECT_URL] || '/';
  }

  setColorMode(colorMode: 'dark' | 'light' | null) {
    this.colorModeService.setColorMode(colorMode);
  }

  onSubmit() {
    if (isEmpty(this.form.controls.username.errors)) {
      this.form.controls.username.setErrors(null);
    }

    if (this.form.valid) {
      const credentials = this.form.getRawValue();

      this.loginService.login(credentials).subscribe({
        next: () => this.router.navigateByUrl(this.redirectUrl),
        error: () => {
          this.form.controls.username.setErrors({});
          this.form.controls.password.setErrors({ invalid: true });
          this.cdr.markForCheck();
        },
      });
    }
  }
}
