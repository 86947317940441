import { Injectable } from '@angular/core';
import { Store }      from '@ngrx/store';
import {
  FileExtension,
  ShareResponse,
}                     from '~domain/enums';
import {
  downloadDocument,
  respondToDocument,
}                     from '../state/app.actions';
import {
  selectDocumentDownloadingId,
  selectDocumentUpdating,
}                     from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class RespondToDocumentService {
  documentUpdating$ = this.store.select(selectDocumentUpdating);
  documentDownloadingId$ = this.store.select(selectDocumentDownloadingId);

  constructor(
    private readonly store: Store,
  ) {
  }

  respondToDocument(id: string, response: ShareResponse, clientId: string, message?: string) {
    this.store.dispatch(respondToDocument({ id, response, clientId, message }));
  }

  downloadDocument(id: string, clientId: string, name: string, extension: FileExtension) {
    this.store.dispatch(downloadDocument({ id, clientId, name, extension }));
  }

}
